import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import CenterLoading from "../../../components/common/CenterLoading";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomModal from "../../../components/Modals/CustomModal";
import rulesApi from "../../../services/apis/rulesApi";
import StorageManager from "../../library/utils/storageManager";
import axios from "axios";
import { nameCapitalize } from "../../../utils/coreUtils";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./DataStreamReport.scss";
import PrimaryButton from "../../../components/Form/Buttons/PrimaryButton";
import moment from "moment";
import ReactSelect from "react-select";
function DataStreamReport() {
  const [isLoading, setIsLoading] = useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [viewReportJson, setViewReportJson] = useState(false);
  const [jsonToView, setJsonToView] = useState();
  const [customReportData, setCustomReportData] = useState([]);
  const [t, i18n] = useTranslation();
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedDateOperator, setSelectedDateOperator] = useState("");
  const [row, setRow] = useState({})
  const [date, setDate] = useState([
    {
      label: t("processingScreen.dateEqual"),
      value: "equal",
    },
    {
      label: t("processingScreen.dateGreater"),
      value: "greater",
    },
    {
      label: t("processingScreen.dateLess"),
      value: "less",
    },
    {
      label: t("processingScreen.dateIsBetween"),
      value: "between",
    },
  ]);
  const selectedDataStream = StorageManager.getSelectedDataStream();

  const streamObj = selectedDataStream
    ? JSON.parse(selectedDataStream)
    : undefined;

  const ruleResponse = async () => {
    try {
      setIsLoading(true);
      const response = await rulesApi.getResultReportByDSConfigId({
        dsConfigId: streamObj.dataStreamConfigId,
      });

      setCustomReportData(response?.data);

    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    ruleResponse();
  }, []);
  const showViewJsonModal = async (streamObj) => {
    try {
      const jsonContent = await axios.get(streamObj?.jsonFileUrl);

      setJsonToView(JSON.stringify(jsonContent.data, null, 4));
    } catch (error) { }
  };
  const columns = useMemo(
    () => [
      {
        name: nameCapitalize(t("processingScreen.columns.report")),
        selector: (row) => {

          return (
            <div>
              <OverlayTrigger
                placement="auto"
                overlay={(props) => (
                  <Tooltip id="button-tooltip" {...props}>
                    {nameCapitalize(row?.report)}
                  </Tooltip>
                )}
              >
                <a className="report-name-text active">
                  <div
                    onClick={() => {
                      console.log("row", row);
                      setViewReportJson(true);

                      showViewJsonModal(row);
                      setRow(row)
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {row?.report}
                  </div>
                </a>
              </OverlayTrigger>
            </div>
          );
        },
        minWidth: "100px",
        className: "",
      },
      {
        name: t("processingScreen.columns.version"),
        selector: (row) => row?.version,
        minWidth: "180px",
        center: true,
      },
      {
        name: t("processingScreen.columns.status"),
        selector: (row) => row?.status,
        minWidth: "180px",
        center: true,
      },
      {
        name: t("processingScreen.columns.processedRule"),
        selector: (row) => row?.processedRules,
        minWidth: "180px",
        center: true,
      },
      {
        name: t("processingScreen.columns.date"),
        selector: (row) => row?.createdAt,

        minWidth: "180px",
        center: true,
      },
    ],
    []
  );

  // const handleDownload = () => {
  //   window.location.href = row?.jsonFileUrl;
  // }

  const searchReport = useMemo(() => {
    return (
      <>
        <div className="form-group col" style={{ paddingRight: "8px" }}>
          <input
            className="form-control"
            placeholder={t("processingScreen.searchReportplaceName")}
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              if (e.target.value === "") {
                setResetPaginationToggle(!resetPaginationToggle);
              }
            }}
          />
        </div>
        <div className="form-group col justify-content-end p-2">
          {/* <label class="mb-2 form-label" style={{ fontSize: "16px" }}>
            {t("processingScreen.dateOperator")}
          </label> */}
          <ReactSelect
            classNamePrefix="select"
            options={date}
            value={date.find((item) => item.value === selectedDateOperator)}
            placeholder={t("processingScreen.dateDropPlaceholder")}
            menuPosition="fixed"
            onChange={(event) => {
              setSelectedDateOperator(event.value);
            }}
          />
        </div>
        {["greater", "between", "less", "equal"].includes(
          selectedDateOperator
        ) && (
            <div className="form-group col justify-content-end p-2">
              {/* <label class="mb-2 form-label" style={{ fontSize: "16px" }}>
              {t("processingScreen.startDate")}
            </label> */}
              <input
                className="form-control"
                type="date"
                value={startDate}
                onChange={(e) => {
                  setStartDate(e.target.value);
                  if (e.target.value === "") {
                    setResetPaginationToggle(!resetPaginationToggle);
                  }
                }}
              />
            </div>
          )}
        {selectedDateOperator === "between" && (
          <div className="form-group col justify-content-end p-2">
            {/* <label class="mb-2 form-label" style={{ fontSize: "16px" }}>
              {t("processingScreen.endDate")}
            </label> */}
            <input
              className="form-control"
              type="date"
              value={endDate}
              onChange={(e) => {
                setEndDate(e.target.value);
                if (e.target.value === "") {
                  setResetPaginationToggle(!resetPaginationToggle);
                }
              }}
            />
          </div>
        )}

        <div className="col d-flex justify-content-end align-item-end mb-3 clear-btn-pad">
          <PrimaryButton
            type="reset"
            icon="fa-solid fa-arrows-rotate"
            title={t("processingScreen.clear")}
            className="ms-3"
            onClick={() => {
              setSearch("");
              setStartDate("");
              setEndDate("");
              setSelectedDateOperator("");
            }}
          />
        </div>
      </>
    );
  }, [search, resetPaginationToggle, startDate, selectedDateOperator, endDate]);
  const filteredReports = customReportData.filter((item) => {
    const date = moment(item.createdAt);

    const isGreater =
      selectedDateOperator === "greater"
        ? startDate
          ? date.isSameOrBefore(moment(startDate), "date")
          : true
        : true;

    const isLesser =
      selectedDateOperator === "less"
        ? startDate
          ? date.isSameOrAfter(moment(startDate), "date")
          : true
        : true;

    const isBetween =
      selectedDateOperator === "between"
        ? startDate && endDate
          ? date.isSameOrAfter(moment(startDate), "date") &&
          date.isSameOrBefore(moment(endDate), "date")
          : true
        : true;
    const isEqual =
      selectedDateOperator === "equal"
        ? startDate
          ? date.isSame(moment(startDate), "day")
          : true
        : true;

    return (
      isGreater &&
      isLesser &&
      isBetween &&
      isEqual &&
      ((item.report &&
        item.report.toLowerCase().includes(search.toLowerCase())) ||
        (item.status &&
          item.status.toLowerCase().includes(search.toLowerCase())))
    );
  });

  //  Custom Style for Checkbox
  const Checkbox = React.forwardRef(({ onClick, ...rest }, ref) => {
    return (
      <>
        <div className="form-check form-check-sm form-check-custom">
          <input
            type="checkbox"
            className="form-check-input"
            ref={ref}
            onClick={onClick}
            {...rest}
          />
        </div>
      </>
    );
  });
  const customStyles = {
    title: {
      style: {
        color: "#004e5c !important",
        fontWeight: "800",
      },
    },
    rows: {
      style: {
        borderBottom: "0px !important",
      },
    },
    headCells: {
      style: {
        fontSize: "14px",
        fontWeight: "800",
        color: "#004e5c !important",
        borderBottom: "0px !important",
      },
    },
    cells: {
      style: {
        fontSize: "14px",
      },
    },
  };

  return (
    <div className="container-scroller">
      <CustomModal
        title={t("processingScreen.columns.report")}
        show={viewReportJson}
        body={
          <div>
            <div
              className="pop-up-msg"
              style={{ maxHeight: "50vh", overflow: "auto" }}
            >

              <pre style={{ backgroundColor: "#ecebfb" }}>{jsonToView}</pre>
            </div>
          </div>
        }
        centered
        customButtons={<div className="row" >
          <div className="col-auto" >
            <PrimaryButton
              title={t("CommonKeys.okButtonTitle")}
              onClick={() => setViewReportJson(false)}
            />
          </div>
          <div className="col-auto">
            <a
              href={row?.jsonFileUrl}
              download="filename.json"
              className="bg-app-primary d-block p-2 rounded-2 text-white"
            >
              {t("CommonKeys.download")}
            </a>
          </div>
        </div>}


      />
      {/* <!-- partial --> */}
      <div className="container-fluid page-body-wrapper p-0">
        {/* <!-- partial --> */}
        <div className="main-panel">
          <div className="content-wrapper">
            {/* <!-- breadcrumb-start --> */}
            <div className="page-breadcrumb d-none d-md-flex align-items-center mb-5">
              <div className="breadcrumb-title pe-3">
                {" "}
                {streamObj?.customDataStreamName}
              </div>
              <div className="ps-3">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb mb-0 p-0">
                    <li className="breadcrumb-item">
                      <a href="#">
                        <i className="fa-solid fa-house"></i>
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {t("processingScreen.ruleEngine")}
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {t("processingScreen.dataStreamReport")}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            {/* <!-- breadcrumb-end --> */}
            <div className="col-12">
              <div className="card main-card-table">
                <div className="card-header">
                  <div className="row align-items-center">
                    <div className="col-12 col-md-5">
                      <div className="card-label">
                        <img
                          src="/assets/images/report-icon.png"
                          alt=""
                          className="me-2"
                          style={{ width: "29px", height: "27px" }}
                        />{" "}
                        {t("processingScreen.dataStreamReport")}-{" "}
                        {streamObj?.customDataStreamName}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body" style={{ marginRight: "0px" }}>
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="tab-content" id="v-pills-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="work"
                          role="tabpanel"
                          aria-labelledby="work-tab"
                        >
                          <label
                            class="mb-2 form-label"
                            style={{ fontSize: "16px" }}
                          >
                            {t("processingScreen.searchReportLabel")}
                          </label>
                          <div className="table-responsive dataStream-report">
                            <DataTable
                              responsive
                              columns={columns}
                              data={filteredReports}
                              noDataComponent={t(
                                "processingScreen.columns.noData"
                              )}
                              progressPending={isLoading}
                              progressComponent={
                                <CenterLoading minHeight={"150px"} />
                              }
                              paginationResetDefaultPage={resetPaginationToggle}
                              pagination
                              selectableRows
                              striped
                              selectableRowsSingle
                              selectableRowsComponent={Checkbox}
                              customStyles={customStyles}
                              subHeader
                              subHeaderAlign="left"
                              subHeaderWrap={true}
                              subHeaderComponent={searchReport}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- content-wrapper ends --> */}
          {/* <!-- partial:partials/_footer.html --> */}
          <footer className="footer">
            <div className="d-sm-flex justify-content-center">
              <p className="mb-0">
                AI Cost Control @2023 | Developed By :{" "}
                <a href="#" target="_blank">
                  Om Software Pvt Ltd
                </a>
              </p>
            </div>
          </footer>
          {/* <!-- partial --> */}
        </div>
        {/* <!-- main-panel ends --> */}
      </div>
      {/* <!-- page-body-wrapper ends --> */}
    </div>
  );
}

export default DataStreamReport;
