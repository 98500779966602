import React from "react";


function ViewCompany() {
  return <div>
    <div className="container-scroller">
   
    {/* <!-- partial --> */}
    <div className="container-fluid page-body-wrapper p-0">
      
      {/* <!-- partial --> */}
      <div className="main-panel">
        <div className="content-wrapper">
            {/* <!-- breadcrumb-start --> */}
            <div className="page-breadcrumb d-none d-md-flex align-items-center mb-5">
              <div className="breadcrumb-title pe-3">Company</div>
              <div className="ps-3">
                  <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0 p-0">
                          <li className="breadcrumb-item"><a href="#"><i className="fa-solid fa-house"></i></a>
                          </li>
                          <li className="breadcrumb-item active" aria-current="page">View Company</li>
                      </ol>
                  </nav>
              </div>
          </div>
          {/* <!-- breadcrumb-end --> */}
          <div className="col-12 col-lg-8 col-md-12 mx-auto">
            <div className="card main-card-table">
                <div className="card-header">
                    <div className="row align-items-center">
                        <div className="col-12">
                            <div className="card-label mb-0 d-flex">
                              <i className="fa-solid fa-building me-3 fs-4 text primary"></i> View Company
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="form">
                       <div className="col-12">
                        <div className="row">
                            <h6 className="mb-3"><i className="fa-solid fa-user me-2 text-primary"></i>Business Information Details</h6>
                               <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                   <label for="" className="form-label text-primary mb-1">Company Id : </label>
                                   <p className="mb-0">101</p>
                                </div>
                               </div>
                               <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                   <label for="" className="form-label text-primary mb-1">Company Name : </label>
                                   <p className="mb-0">Ajay Enterprices</p>
                                </div>
                               </div>
                               <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                   <label for="" className="form-label text-primary mb-1">Email Id : </label>
                                   <p className="mb-0">ajay@gmail.com</p>
                                </div>
                               </div>
                               <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                   <label for="" className="form-label text-primary mb-1">Mobile No : </label>
                                   <p className="mb-0">9087678565</p>
                                </div>
                               </div>
                               <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                   <label for="" className="form-label text-primary mb-1">Address : </label>
                                   <p className="mb-0"> Street height, Pune, Maharashtra</p>
                                </div>
                               </div>
                               <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                   <label for="" className="form-label text-primary mb-1">Country : </label>
                                   <p className="mb-0">India</p>
                                </div>
                               </div>
                               <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                   <label for="" className="form-label text-primary mb-1">State : </label>
                                   <p className="mb-0">Maharashtra</p>
                                </div>
                               </div>
                               <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                   <label for="" className="form-label text-primary mb-1">City : </label>
                                   <p className="mb-0">Pune</p>
                                </div>
                               </div>
                               <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                   <label for="" className="form-label text-primary mb-1">Postal Code : </label>
                                   <p className="mb-0">411045</p>
                                </div>
                               </div>
                               <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                   <label for="" className="form-label text-primary mb-1">Tax Id : </label>
                                   <p className="mb-0">1234</p>
                                </div>
                               </div>
                           </div>
                           <hr className="mt-0"/>
                           <div className="row">
                            <h6 className="mb-3"><i className="fa-solid fa-address-card me-2 text-primary"></i>Primary Contact Details</h6>
                               <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                   <label for="" className="form-label text-primary mb-1">Primary Contact Person First Name : </label>
                                   <p className="mb-0">John</p>
                                </div>
                               </div>
                               <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                   <label for="" className="form-label text-primary mb-1">Primary Contact Person Last Name : </label>
                                   <p className="mb-0">Deo</p>
                                </div>
                               </div>
                               <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                   <label for="" className="form-label text-primary mb-1">Primary Email Id : </label>
                                   <p className="mb-0">John@gmail.com</p>
                                </div>
                               </div>
                               <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                   <label for="" className="form-label text-primary mb-1">Primary Contact Mobile No : </label>
                                   <p className="mb-0">5456876767</p>
                                </div>
                               </div>
                               <hr className="mt-0"/>  
                               <div className="row">
                                    <div className="col-12 col-md-6">
                                        <h6 className="mb-3"><i className="fa-solid fa-credit-card me-2 text-primary mb-1"></i>Access Details</h6>
                                    </div>
                                    <div className="col-12 col-md-6 text-end">
                                        <a href="#" type="button" className="btn"><i className="fa-sharp fa-solid fa-rotate me-2"></i>Change Password</a>
                                    </div>
                                   <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                       <label for="" className="form-label text-primary mb-1">Access URL : </label>
                                       <p className="mb-0">www.abc.com</p>
                                    </div>
                                   </div>
                                   <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                       <label for="" className="form-label text-primary mb-1">Password : </label>
                                       <p className="mb-0">******</p>
                                    </div>
                                   </div>
                                   <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                       <label for="" className="form-label text-primary mb-1">Localization : </label>
                                       <p className="mb-0">Pune</p>
                                    </div>
                                   </div>
                                    <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                   <label for="" className="form-label text-primary mb-1">Is Primary Email Verified : </label><br/>
                                   <p className="mb-0">Yes</p>
                                </div>
                               </div>
                               <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                   <label for="" className="form-label text-primary mb-1">Is Mobile Verified : </label><br/>
                                   <p className="mb-0">No</p>
                                </div>
                               </div>
                           </div>
                            <div className="text-end mt-4">
                                <a href="#" type="button" className="btn"><i className="fa-regular fa-floppy-disk me-2"></i>Save</a>
                            </div>
                       </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        {/* <!-- content-wrapper ends --> */}
        {/* <!-- partial:partials/_footer.html --> */}
        <footer className="footer">
          <div className="d-sm-flex justify-content-center">
            <p className="mb-0">AI Cost Control @2022 | Developed By : <a href="#" target="_blank">Om Software</a>
            </p>
          </div>
        </footer>
        {/* <!-- partial --> */}
      </div>
      {/* <!-- main-panel ends --> */}
    </div>
    {/* <!-- page-body-wrapper ends --> */}
  </div>
  {/* <!-- container-scroller --> */}
  </div>
  </div>;
}

export default ViewCompany;
