import React, { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  APPSTRING,
  buttonStrings,
  icons,
  placeholders,
} from "../../../constants";
import { isEmpty } from "../../library/utils/common";
import Input from "../../../library/components/input.component";
import LeftPanel from "../components/leftPanel.component";
import RightPanelHeader from "../components/rightPanelHeader.component";
import { useDispatch, useSelector } from "react-redux";
import {
  setPasswordForForgotPasswordAction,
  setPasswordForNewUserAction,
} from "../redux/actions/auth.action";
import { toast } from "react-toastify";
import { ToastComponent } from "../../../library/components/toast.component";
import { colors } from "../../../library/theme/palette";
import { useTranslation } from "react-i18next";
import { getPathPrefix } from "../../../utils/coreUtils";
import ToastUtils from "../../../utils/ToastUtils";

const FooterButton = ({
  handleContinue,
  title,
  buttonStyle = {},
  disabled = false,
  isLoading,
}) => {
  const [t, i18n] = useTranslation();

  return (
    <>
      <div className="form-group text-center">
        <button
          onClick={handleContinue}
          type="submit"
          className="btn btn-fill text-uppercase"
          disabled={disabled}
          style={buttonStyle}
        >
          {isLoading && (
            <img
              src={"https://www.ssdream.co.kr/front/event/img/loading.gif"}
              color={colors.white}
              height={"25"}
              width={"25"}
            />
          )}
          {title}
        </button>
      </div>
      <div className="form-group text-center">
        <NavLink to={getPathPrefix("login")} className="">
          {t("CommonKeys.backTo")} <b>{t("CommonKeys.login_hyphen")}</b>
        </NavLink>
      </div>
    </>
  );
};

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const setPasswordForForgotPasswordData = useSelector(
    (state) => state.authReducer.setPasswordForForgotPasswordData
  );
  const changeForgetPasswordMailData = useSelector(
    (state) => state.authReducer.changeForgetPasswordMailData
  );
  const isLoading = useSelector((state) => state.authReducer.isLoading);
  const [t, i18n] = useTranslation();

  const [resetPasswordState, setResetPasswordState] = useState({
    password: "",
    confirmPassword: "",
  });
  const [isSuccess, setIsSuccess] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);

  const handleChange = (e, type) => {
    resetPasswordState[type] = e.target.value;
    setResetPasswordState({
      ...resetPasswordState,
    });
  };

  const handleSubmit = async (e) => {
    console.log(localStorage.getItem("encKeyLink"));
    e.preventDefault();
    if (
      !isEmpty(resetPasswordState.password) &&
      !isEmpty(resetPasswordState.confirmPassword)
    ) {
      if (resetPasswordState.password === resetPasswordState.confirmPassword) {
        if (localStorage.getItem("encKeyLink") != null) {
          await dispatch(
            setPasswordForNewUserAction(
              localStorage.getItem("encKeyLink"),
              resetPasswordState.password,
              location.state.otpStates
            )
          )
            .then((res) => {
              setIsSuccess(true);
              if (res.status != 200) {
                ToastUtils.serverError(t(`statusCodes.${res?.code}`));
              } else {
                toast.success(t(`statusCodes.${res?.code}`));
              }
            })
            .catch((e) => {
              toast.error(e.data.message);
            });
        } else {
          await dispatch(
            setPasswordForForgotPasswordAction(
              changeForgetPasswordMailData,
              resetPasswordState.password,
              location.state.otpStates
            )
          )
            .then((res) => {
              setIsSuccess(true);
              if (res.status != 200) {
                ToastUtils.serverError(t(`statusCodes.${res?.code}`));
              } else {
                toast.success(t(`statusCodes.${res?.code}`));
              }
            })
            .catch((e) => {
              toast.error(e.data.message);
            });
        }
      } else {
        // toast.error("password does not match");
        toast.error(t("errorKeys.passwordNotMatch"));
      }
    } else {
      // toast.error("log in fail");
      toast.error(t("errorKeys.logInFail"));
    }
  };

  const handleContinue = () => {
    navigate(getPathPrefix() + "login");
  };

  const isDisable = () => {
    return (
      isEmpty(resetPasswordState.password) ||
      isEmpty(resetPasswordState.confirmPassword)
    );
  };

  return (
    <section className="page main-signin-wrapper container-layout align-items-center">
      <div className="row signpages bg-app-primary">
        <div className="col-md-12">
          <div className="row">
            <LeftPanel />
            {isSuccess ? (
              <div className="col-lg-6 right-panel">
                <div className="form-container d-flex flex-column justify-content-center">
                  <div className="pass-check d-flex">
                    <i className={icons.solid}></i>
                  </div>
                  <div className="heading">
                    <h4> {t("resetPassword.passwordChange")}</h4>
                    <h6> {t("resetPassword.passwordSuccess")}</h6>
                  </div>
                  <form>
                    <FooterButton
                      title={t("resetPassword.continue")}
                      handleContinue={handleContinue}
                    />
                  </form>
                </div>
              </div>
            ) : (
              <div className="col-lg-6 right-panel">
                <div className="form-container d-flex flex-column">
                  <img
                    src={"/assets/images/logo-light-full.png"}
                    className="header-brand-img text-start mb-4 mx-auto"
                    alt="logo"
                  />
                  <form className="d-flex flex-column flex-fill justify-content-center">
                    {/* <RightPanelHeader title={t("language.reset_password")} /> */}
                    <h4
                      className="text-primary-app mb-4 text-center"
                      style={{ fontWeight: "600" }}
                    >
                      {t("resetPassword.title")}
                    </h4>
                    <div className="form-group">
                      <Input
                        icon={
                          !showNewPass
                            ? "fa-regular fa-eye field-icon"
                            : "fa-regular fa-eye-slash field-icon"
                        }
                        onChange={(e) => handleChange(e, "password")}
                        value={resetPasswordState.password}
                        placeholder={t("resetPassword.newPasswordPlaceholder")}
                        type={showNewPass ? "text" : "password"}
                        onClick={(e) => setShowNewPass((prev) => !prev)}
                      />
                    </div>
                    <div className="form-group mb-4">
                      <Input
                        icon={
                          !showConfirmPass
                            ? "fa-regular fa-eye field-icon"
                            : "fa-regular fa-eye-slash field-icon"
                        }
                        onChange={(e) => handleChange(e, "confirmPassword")}
                        value={resetPasswordState.confirmPassword}
                        placeholder={t(
                          "resetPassword.confirmPasswordPlaceholder"
                        )}
                        type={showConfirmPass ? "text" : "password"}
                        onClick={(e) => setShowConfirmPass((prev) => !prev)}
                      />
                    </div>
                    <FooterButton
                      // title={t("language.update_password")}
                      title={t("resetPassword.updatePassword")}
                      handleContinue={handleSubmit}
                      disabled={isDisable()}
                      buttonStyle={
                        isDisable()
                          ? { opacity: 0.4, cursor: "not-allowed" }
                          : {}
                      }
                      isLoading={isLoading}
                    />
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/*<ToastComponent /> */}
    </section>
  );
};

export default ResetPassword;
