import { createRef } from "react";
import ReactSelect from "react-select";
import { AICCArithmeticSelector } from "../../core/AICCArithmeticSelector";

export function AICCFieldInputValue({
  selectedOperator,
  language,
  value,
  valueFieldGroup = "",
  onChange,
  useColumnGroup,
  columnsWithGroup,
  fieldList,
  onValueFieldGroup,
  finalColumnList,
  selectedRightArOp,
  rightArOpValue,
  onOpChange,
  onOpValueChange,
  rightLeftArithmetic,
  rightRightArithmetic,
  onCheckChanged,
  allowArithmetic,
}) {
  // TODO: Showing selected value is not done yet.

  const fieldListRef = createRef();

  const fieldListOpt1Ref = createRef();
  const fieldListOpt2Ref = createRef();

  const styles = {
    menu: (base) => ({
      ...base,
      width: "max-content",
      minWidth: "100%",
    }),
  };

  if ((selectedOperator?.value ?? "").includes("_between")) {
    const splittedGroupValues = (valueFieldGroup ?? "")
      .split(",")
      .map((e) => e.trim());
    const splittedChildValues = (value ?? "").split(",").map((e) => e.trim());

    // field list for first group and child
    const firstSelectedValue = columnsWithGroup.find(
      (e) => e.value == splittedGroupValues[0]
    );

    const firstFinalColumns = useColumnGroup
      ? firstSelectedValue?.subFields ?? []
      : fieldList;

    const firstSelectedChild = firstFinalColumns.find(
      (e) => e.value == splittedChildValues[0]
    );

    // field list for second group and child
    const secondSelectedValue = columnsWithGroup.find(
      (e) => e.value == (splittedGroupValues[1] ?? "")
    );

    const secondFinalColumns = useColumnGroup
      ? secondSelectedValue?.subFields ?? []
      : fieldList;

    const secondSelectedChild = secondFinalColumns.find(
      (e) => e.value == (splittedChildValues[1] ?? "")
    );

    const ariOp = (selectedRightArOp ?? "").split(",");
    const ariOpVal = (rightArOpValue ?? "").split(",");

    return (
      <div className="row g-0 gap-2 align-items-center">
        <div className="form-group mb-0 col-auto">
          {useColumnGroup && (
            <ReactSelect
              options={columnsWithGroup}
              classNamePrefix={"select"}
              menuPosition={"fixed"}
              styles={styles}
              placeholder={
                language?.fieldStreamPlaceholder ?? "Select Field Stream"
              }
              value={firstSelectedValue}
              noOptionsMessage={() => language?.noFieldStreamOptions}
              onChange={(option) => {
                if (onValueFieldGroup) {
                  if (
                    fieldListOpt1Ref?.current &&
                    option?.value !== valueFieldGroup
                  ) {
                    fieldListOpt1Ref.current.clearValue();
                  }

                  const temp = Array.from(splittedGroupValues);

                  temp[0] = option?.value ?? "";

                  onValueFieldGroup("" + temp.join(","));
                }
              }}
            />
          )}
        </div>
        <div className="form-group mb-0 col-auto">
          <ReactSelect
            ref={fieldListOpt1Ref}
            options={firstFinalColumns}
            classNamePrefix={"select"}
            menuPosition={"fixed"}
            styles={styles}
            placeholder={language?.fieldPlaceholder ?? "Select Operator"}
            value={firstSelectedChild}
            noOptionsMessage={() => language?.noFieldOptions}
            onChange={(option) => {
              if (onChange) {
                onChange("" + option?.value, "min");
              }
            }}
          />
        </div>

        {allowArithmetic && (firstSelectedChild?.type === "number" || firstSelectedChild?.type === "decimal") && (
          <div className="col-auto">
            <AICCArithmeticSelector
              allowValueSource={false}
              selectedOp={ariOp[0] ?? ""}
              value={ariOpVal[0] ?? ""}
              enabled={rightLeftArithmetic}
              onCheckChanged={(val) => {
                onCheckChanged(val, "min");
              }}
              onOpChange={(val) => {
                onOpChange(val, "min");
              }}
              onChange={(val) => {
                onOpValueChange(val, "min");
              }}
            />
          </div>
        )}

        <div className="col-auto">
          <span
            className="d-inline-block"
            style={{
              marginRight: "6px",
              marginLeft: "6px",
            }}
          >
            {language?.betweenAndLabel ?? "and"}
          </span>
        </div>

        <div className="form-group mb-0 col-auto">
          {useColumnGroup && (
            <ReactSelect
              options={columnsWithGroup}
              classNamePrefix={"select"}
              menuPosition={"fixed"}
              styles={styles}
              placeholder={
                language?.fieldStreamPlaceholder ?? "Select Field Stream"
              }
              value={secondSelectedValue}
              noOptionsMessage={() => language?.noFieldStreamOptions}
              onChange={(option) => {
                if (onValueFieldGroup) {
                  if (
                    fieldListOpt2Ref?.current &&
                    option?.value !== valueFieldGroup
                  ) {
                    fieldListOpt2Ref.current.clearValue();
                  }
                  const temp = Array.from(splittedGroupValues);

                  temp[1] = option?.value ?? "";

                  onValueFieldGroup("" + temp.join(","));
                }
              }}
            />
          )}
        </div>
        <div className="form-group mb-0 col-auto">
          <ReactSelect
            ref={fieldListOpt2Ref}
            options={secondFinalColumns}
            classNamePrefix={"select"}
            menuPosition={"fixed"}
            styles={styles}
            placeholder={language?.fieldPlaceholder ?? "Select Operator"}
            value={secondSelectedChild}
            noOptionsMessage={() => language?.noFieldOptions}
            onChange={(option) => {
              if (onChange) {
                onChange("" + option?.value, "max");
              }
            }}
          />
        </div>

        {allowArithmetic && (secondSelectedChild?.type === "number" || secondSelectedChild?.type === "decimal") && (
          <div className="col-auto">
            <AICCArithmeticSelector
              allowValueSource={false}
              selectedOp={ariOp[1] ?? ""}
              value={ariOpVal[1] ?? ""}
              enabled={rightRightArithmetic}
              onCheckChanged={(val) => {
                onCheckChanged(val, "max");
              }}
              onOpChange={(val) => {
                onOpChange(val, "max");
              }}
              onChange={(val) => {
                onOpValueChange(val, "max");
              }}
            />
          </div>
        )}
      </div>
    );
  }

  const finalListColumn = columnsWithGroup.find(
    (e) => e.value == valueFieldGroup
  );

  const selectedChild = finalColumnList.find((e) => e.value == value);

  return (
    <div className="row g-0 gap-2 align-items-center">
      <div className="form-group mb-0 col-auto">
        {useColumnGroup && (
          <ReactSelect
            options={columnsWithGroup}
            classNamePrefix={"select"}
            menuPosition={"fixed"}
            styles={styles}
            placeholder={
              language?.fieldStreamPlaceholder ?? "Select Field Stream"
            }
            value={finalListColumn}
            noOptionsMessage={() => language?.noFieldStreamOptions}
            onChange={(option) => {
              if (onValueFieldGroup) {
                if (
                  fieldListRef?.current &&
                  option?.value !== valueFieldGroup
                ) {
                  fieldListRef.current.clearValue();
                }
                onValueFieldGroup("" + option?.value);
              }
            }}
          />
        )}
      </div>
      <div className="form-group mb-0 col-auto">
        <ReactSelect
          ref={fieldListRef}
          options={finalColumnList}
          classNamePrefix={"select"}
          menuPosition={"fixed"}
          styles={styles}
          value={selectedChild}
          placeholder={language?.fieldPlaceholder ?? "Select Operator"}
          noOptionsMessage={() => language?.noFieldOptions}
          onChange={(option) => {
            if (onChange) {
              onChange("" + option?.value, "max");
            }
          }}
        />
      </div>

      {allowArithmetic && (selectedChild?.type === "number" || selectedChild?.type === "decimal") && (
        <div className="col-auto">
          <AICCArithmeticSelector
            allowValueSource={false}
            selectedOp={selectedRightArOp}
            value={rightArOpValue}
            enabled={rightLeftArithmetic}
            onCheckChanged={(val) => {
              onCheckChanged(val);
            }}
            onOpChange={(val) => {
              onOpChange(val);
            }}
            onChange={(val) => {
              onOpValueChange(val);
            }}
          />
        </div>
      )}
    </div>
  );
}
