import { t } from "i18next";
import React, {
  useEffect,
  useMemo,
  useState,
  createRef,
  useCallback,
} from "react";
import ReactSelect from "react-select";
import openApi from "../../services/apis/openApi";
import DangerButton from "../Form/Buttons/DangerButton";
import PrimaryButton from "../Form/Buttons/PrimaryButton";
import CustomModal from "../Modals/CustomModal";

import "../../assets/scss/queryBuilder.scss";
import {
  getLastBySplit,
  getWidgetTypeBasedOnFieldType,
  getWidgetTypeBasedOnFieldTypeNoAny,
} from "../../utils/coreUtils";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import RequiredDot from "../Form/RequiredDot";
import CreatableSelect from "react-select/creatable";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import RuleBuilderConvert from "../../utils/converter/RuleBuilderConvert";
import ChipInput from "../Form/Inputs/ChipInput";
import AICCRuleBuilder from "../../lib/aiccRuleBuilder/components/AICCRuleBuilder";
import CommonConfig from "./CommonConfig.scss";

export const initialWhereClause = {
  // midOperator: "and",
};

const CommonFieldConfig = (props) => {
  const {
    modalTitle,
    // handleLevelClick,
    modalDataState,
    handleSave,
    showGroupFields,
    showModal,
    setShowModal,
    onlyGroupOptions,
    ruleBuilderFields,
    mappedFieldJson,
  } = props;

  const groupNameSelect = createRef();
  const fieldTypeSelectRef = createRef();
  const childComponentRef = createRef();
  const groupBySelectRef = createRef();
  const aggregateFunctionRef = createRef();

  const [showQuery, setShowQuery] = useState(false);
  const [confirmQueryReset, setConfirmQueryReset] = useState(false);

  const [confirmCancel, setConfirmCancel] = useState(false);
  const [aggregateList, setAggregateList] = useState([]);
  const [originalData, setOriginalData] = useState("");
  const [modalData, setModalData] = useState({
    parentHeader: "",
    fieldName: "",
    pathName: "",
    groupName: "",
    fieldType: "",
    indexNumber: "",
    aggregateFunctionId: "",
    selectedChild: undefined,
    geographicalField: false,
    isOptionalField: false,
    fields: [],
    fieldList: [],
    aggFieldList: [],
    groupByList: undefined,
    whereClause: [{ ...initialWhereClause, id: new Date().getTime() }],
  });

  const operatorList = useMemo(() => {
    return [
      {
        label: "=",
        value: "=",
      },
      {
        label: "<",
        value: "<",
      },
      {
        label: "<=",
        value: "<=",
      },
      {
        label: ">",
        value: ">",
      },
      {
        label: ">=",
        value: ">=",
      },
      {
        label: "Like",
        value: "like",
      },
    ];
  }, []);

  useEffect(() => {
    if (modalDataState) {
      // console.log("modalDataState", modalDataState);
      if (
        groupNameSelect?.current &&
        (!modalDataState.groupName ||
          modalData?.fieldName !== modalDataState.fieldName)
      ) {
        groupNameSelect?.current?.clearValue();

        if (fieldTypeSelectRef?.current) {
          fieldTypeSelectRef?.current?.clearValue();
        }
        if (childComponentRef?.current) {
          childComponentRef?.current?.clearValue();
        }
        if (groupBySelectRef?.current) {
          groupBySelectRef?.current?.clearValue();
        }
      }

      setOriginalData(JSON.stringify(modalDataState));

      setModalData({ ...modalDataState });
    }
  }, [modalDataState]);

  const handleLevelClick = (childConfig, idx) => {
    // const childConfig = selectedOption;
    modalData.fieldList.length = idx;
    if (childConfig) {
      modalData.fieldList.push(childConfig);
    }

    // console.log(childConfig);

    let groupByList = undefined;

    // check current select object is other then array and oject
    // additionally check that previous field list is array or object.
    if (
      !["array", "object"].includes(childConfig?.type) &&
      ["array", "object"].includes(modalData.fieldList[idx - 1]?.type)
    ) {
      groupByList = modalData.fieldList[idx - 1];
    } else {
      // if only single level child is selected then assign main parent modal data.
      groupByList = { ...modalData };
    }

    let fieldType = undefined;

    if (childConfig) {
      fieldType = childConfig?.type;
    }

    if (["array", "object"].includes(fieldType)) {
      fieldType = undefined;
    }

    setModalData((prev) => ({
      ...prev,
      pathName: childConfig?.path ?? prev.pathName,
      fieldName: getLastBySplit(childConfig?.label ?? prev.fieldName),
      selectedChild: childConfig,
      groupByList,
      selectedGroupByPath: undefined,
      fieldType: fieldType,
    }));
  };

  /* useEffect(() => {
    if (
      groupBySelectRef?.current &&
      !modalData?.selectedChild?.path &&
      modalData?.selectedGroupByPath
    ) {
      groupBySelectRef.current.clearValue();
    }
  }, [
    modalData?.selectedChild?.path,
    modalData?.selectedGroupByPath,
    groupBySelectRef,
  ]); */

  const getAllAggregateFunction = async () => {
    try {
      const response = await openApi.getAllAggregateFunction();

      if (Array.isArray(response.data)) {
        setAggregateList(response.data);
      }
    } catch (error) { }
  };

  const onCancel = useCallback(() => {
    if (typeof modalData?.dropped === "boolean" && modalData?.dropped) {
      setConfirmCancel(true);
    } else {
      handleSave({
        ...JSON.parse(originalData),
      });
      setShowModal(false);
    }
  }, [handleSave, modalData?.dropped, originalData, setShowModal]);

  useEffect(() => {
    if (!showGroupFields) {
      getAllAggregateFunction();
    }
  }, [showGroupFields]);

  const aggregateOptions = useMemo(
    () => [
      {
        label: t("CommonKeys.selectNoneText"),
        value: -1,
      },
      ...aggregateList.map((e) => ({
        ...e,
        label: e.name,
        value: e.aggregateFunctionId,
      })),
    ],
    [aggregateList]
  );

  const childComponentSelection = useMemo(
    () =>
      (modalData?.fields ?? []).map((e) => ({
        ...e,
        label: e.name,
        value: e.path,
      })),
    [modalData?.fields]
  );

  const isOtherType = useMemo(() => {
    return !["array", "object"].includes(modalData?.type);
  }, [modalData?.type]);

  const isArrayOrObject = useMemo(() => {
    return ["array", "object"].includes(modalData?.type);
  }, [modalData?.type]);

  /* const groupBySelectOptions = useMemo(() => {
    const parentArray = (modalData?.groupByList?.fields ?? []).filter(e => e.type !== "array").map((e) => ({
      ...e,
      label: e.name,
      value: e.path,
    }));
    const flattedArray = parentArray.map(e => e.fields.length > 0 ? e.fields : e).flat(10)

    return flattedArray.map(field => {
      if (field.value) {
        return field
      }
      const splittedPath = field.path.split(".")
      return {
        ...field,
        label: splittedPath.slice(splittedPath.length - 2).join("."),
        value: field.path,
      }
    });
  }, [modalData?.groupByList?.fields]); */

  const groupBySelectOptions = useMemo(() => {
    if (!modalData.pathName) {
      return []
    }
    const listToTravel = mappedFieldJson[modalData.pathName.split(".")[0]];
    const allNestedFields = (fields) => {
      const originalPath = modalData.pathName.substring(0, modalData.pathName.lastIndexOf("."))
      return [
        ...fields.map((e) => [
          ...allNestedFields(e.fields, e.path),
          [
            {
              ...e,
              label: e.path.replace(originalPath + ".", ""),
              value: e.path,
            },
          ],
        ]),
      ];
    };

    return allNestedFields(listToTravel?.fields ?? [])
      .flat(Infinity)
      .filter((field) => !["array", "object"].includes(field.type))

  }, [mappedFieldJson, modalData.pathName]);

  const finalWhereClauses = useMemo(() => {
    return (modalData.whereClause ?? []).filter((e) => !e.isDeleted);
  }, [modalData.whereClause]);

  /* console.log(
    JSON.stringify(
      WhereClauseConvert.formToRequest(QbUtils.getTree(state.tree))
    )
  ); */

  const currentFieldType = useMemo(
    () => getWidgetTypeBasedOnFieldType(modalData?.fieldType),
    [modalData?.fieldType]
  );

  const selectedChildOrCurrentFieldType = getWidgetTypeBasedOnFieldType(
    modalData.selectedChild
      ? modalData.selectedChild?.type
      : modalData?.fieldType
  );

  const disableForCompany = modalData?.createdType === "SUPER_ADMIN";

  const fieldTypeOptions = useMemo(
    () => [
      {
        label: t("fieldConfiguration.fieldConfigPopup.fieldTypeOptions.text"),
        value: "text",
      },
      {
        label: t("fieldConfiguration.fieldConfigPopup.fieldTypeOptions.number"),
        value: "number",
      },
      {
        label: t("fieldConfiguration.fieldConfigPopup.fieldTypeOptions.float"),
        value: "decimal",
      },
      {
        label: t(
          "fieldConfiguration.fieldConfigPopup.fieldTypeOptions.boolean"
        ),
        value: "boolean",
      },
      {
        label: t("fieldConfiguration.fieldConfigPopup.fieldTypeOptions.date"),
        value: "date",
      },
    ],
    []
  );

  const aiccRuleFields = useMemo(
    () => RuleBuilderConvert.aiccRBSchemaToFields(groupBySelectOptions),
    [groupBySelectOptions]
  );

  const isSaveDisabled = useMemo(
    () =>
      modalData.isPrimarySecondary
        ? false
        : !(modalData.groupName ?? "").trim() ||
        !fieldTypeOptions.find(
          (e) =>
            e.value ===
            getWidgetTypeBasedOnFieldTypeNoAny(modalData?.fieldType)
        ) ||
        (!(modalData.fieldName ?? "").trim() &&
          modalData?.fieldType !== "object") /* ||
            (modalData.aggregateFunctionId !== -1 &&
              !modalData.selectedGroupByPath) */,
    [
      fieldTypeOptions,
      modalData.fieldName,
      modalData?.fieldType,
      modalData.groupName,
      modalData.isPrimarySecondary,
    ]
  );

  return (
    <>
      <CustomModal
        size={"xl"}
        show={showModal}
        centered
        title={modalTitle}
        body={
          <div className="form">
            <div className="row">
              {showGroupFields ? (
                <>
                  <div className="col-12 col-lg-6 col-md-6">
                    <div className="form-group">
                      <label htmlFor="" className="form-label">
                        {t(
                          "fieldConfiguration.groupNameChangePopup.groupNameLabel"
                        )}
                      </label>
                      <input
                        type="text"
                        name="groupName"
                        value={modalData?.groupName}
                        onChange={(e) => {
                          setModalData({
                            ...modalData,
                            groupName: e.target.value.trimStart(),
                          });
                        }}
                        className="form-control"
                        placeholder={t(
                          "fieldConfiguration.groupNameChangePopup.groupNameInputPlaceholder"
                        )}
                      />
                    </div>
                  </div>
                  <div className="text-end mt-3">
                    <PrimaryButton
                      title={t("CommonKeys.saveButtonTitle")}
                      icon="fa-regular fa-floppy-disk"
                      disabled={!(modalData.groupName ?? "").trim()}
                      onClick={() => {
                        setShowModal(false);
                        handleSave(modalData);
                      }}
                    />
                    <DangerButton
                      title={t("CommonKeys.cancelButtonTitle")}
                      className="ms-3"
                      icon={"fa-solid fa-xmark"}
                      onClick={() => {
                        setShowModal(false);
                      }}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="col-12 mb-4 fw-bold border rounded-3 key-border">
                    <Form.Check
                      inline
                      label={t(
                        "fieldConfiguration.fieldConfigPopup.useAsGeographicalField"
                      )}
                      style={{
                        color: "#4a526f",
                      }}
                      checked={modalData.geographicalField}
                      id="geographical-field"
                      onChange={(e) => {
                        setModalData((prev) => ({
                          ...prev,
                          geographicalField: e.target.checked,
                        }));
                      }}
                      disabled={
                        !modalData?.dropped && modalData.isPrimarySecondary
                      }
                    />

                    <Form.Check
                      inline
                      label="Is field optional?"
                      style={{
                        color: "#4a526f",
                      }}
                      checked={modalData.isOptionalField}
                      id="optional-field"
                      onChange={(e) => {
                        setModalData((prev) => ({
                          ...prev,
                          isOptionalField: e.target.checked,
                        }));
                      }}
                      disabled={(!modalData?.dropped && disableForCompany)}
                    />

                    {modalData.isPrimarySecondary && (
                      <div className="mt-3">
                        <Form.Check
                          inline
                          type="radio"
                          label={t(
                            "fieldConfiguration.fieldConfigPopup.defineAsPrimaryKey"
                          )}
                          style={{
                            color: "#4a526f",
                          }}
                          id="primary-key"
                          name="primaryOrSecondary"
                          checked={modalData.isPrimarySecondary === "primary"}
                          onChange={(e) => {
                            setModalData((prev) => ({
                              ...prev,
                              isPrimarySecondary: "primary",
                            }));
                          }}
                          disabled
                        />
                        <Form.Check
                          inline
                          type="radio"
                          label={t(
                            "fieldConfiguration.fieldConfigPopup.defineAsSecondaryKey"
                          )}
                          style={{
                            color: "#4a526f",
                          }}
                          checked={modalData.isPrimarySecondary === "secondary"}
                          id="secondary-key"
                          name="primaryOrSecondary"
                          onChange={(e) => {
                            setModalData((prev) => ({
                              ...prev,
                              isPrimarySecondary: "secondary",
                            }));
                          }}
                          disabled
                        />
                      </div>
                    )}
                  </div>
                  <div className="form-border">
                    <div className="col-12 col-lg-6 col-md-6">
                      <div className="form-group">
                        <label for="" className="form-label">
                          {t(
                            "fieldConfiguration.fieldConfigPopup.parentHeaderLabel"
                          )}
                        </label>
                        <input
                          type="text"
                          name="parentHeader"
                          value={modalData?.parentHeader}
                          onChange={(e) => {
                            setModalData({
                              ...modalData,
                              parentHeader: e.target.value.trimStart(),
                            });
                          }}
                          disabled
                          className="form-control"
                          placeholder={modalData?.pathName}
                          readonly
                        />
                      </div>
                    </div>
                    <div className="col-12 col-lg-6 col-md-6">
                      <div className="form-group">
                        <label for="" className="form-label">
                          {t(
                            "fieldConfiguration.fieldConfigPopup.fieldNameLabel"
                          )}
                          <RequiredDot />
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="fieldName"
                          value={modalData?.fieldName}
                          onChange={(e) => {
                            setModalData({
                              ...modalData,
                              fieldName: e.target.value.trimStart(),
                            });
                          }}
                          // disabled={modalData.isParent}
                          /* disabled={
                          !(modalData.fieldName ?? "").trim() && isArrayOrObject
                        } */
                          disabled={
                            (!(modalData.fieldName ?? "").trim() &&
                              modalData?.fieldType === "object") ||
                            modalData?.isPrimarySecondary
                          }
                          placeholder="Field Name"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-lg-6 col-md-6 inactive-builder">
                      <div className="form-group">
                        <label for="" className="form-label">
                          {t(
                            "fieldConfiguration.fieldConfigPopup.pathNameLabel"
                          )}
                        </label>

                        <OverlayTrigger
                          placement="auto"
                          overlay={(props) => (
                            <Tooltip id="button-tooltip" {...props}>
                              {modalData.pathName}
                            </Tooltip>
                          )}
                        >
                          <span className="cursor">
                            <input
                              type="text"
                              name="pathName"
                              value={modalData.pathName}
                              onChange={(e) => {
                                setModalData((prev) => ({
                                  ...prev,
                                  pathName: e.target.value.trimStart(),
                                }));
                              }}
                              disabled
                              className="form-control"
                              placeholder="Person.Name"
                              readonly
                            />
                          </span>
                        </OverlayTrigger>
                      </div>
                    </div>
                    <div className="col-12 col-lg-6 col-md-6">
                      <div className="form-group">
                        <label for="" className="form-label">
                          {t(
                            "fieldConfiguration.fieldConfigPopup.groupNameLabel"
                          )}
                          <RequiredDot />
                        </label>
                        <CreatableSelect
                          ref={groupNameSelect}
                          classNamePrefix="select"
                          options={onlyGroupOptions}
                          value={onlyGroupOptions.find((e) => {
                            return modalData.groupName === e.value;
                          })}
                          noOptionsMessage={() =>
                            t(
                              "fieldConfiguration.fieldConfigPopup.noGroupNameMessage"
                            )
                          }
                          isOptionSelected={childComponentSelection.find(
                            (e) => {
                              return modalData.groupId === e?.groupId;
                            }
                          )}
                          onChange={(e) => {
                            setModalData((prev) => ({
                              ...prev,
                              groupName: e?.value,
                              groupId: e?.groupId ?? 0,
                            }));
                          }}
                          isDisabled
                        />
                      </div>
                    </div>
                    <div className="col-12 col-lg-6 col-md-6">
                      <div className="form-group">
                        <label for="" className="form-label">
                          {t(
                            "fieldConfiguration.fieldConfigPopup.childComponentsLabel"
                          )}
                        </label>
                        <ReactSelect
                          ref={childComponentRef}
                          classNamePrefix="select"
                          options={childComponentSelection}
                          value={childComponentSelection.find((e) => {
                            return (
                              modalData.selectedChild?.path ?? ""
                            ).startsWith(e.path);
                          })}
                          isOptionSelected={childComponentSelection.find(
                            (e) => {
                              return (
                                modalData.selectedChild?.path ?? ""
                              ).startsWith(e.path);
                            }
                          )}
                          onChange={(e) => {
                            if (!e?.value) return;

                            groupBySelectRef?.current?.clearValue();
                            aggregateFunctionRef?.current?.clearValue();
                            setTimeout(() => {
                              handleLevelClick(e, 0);
                            }, 10);
                          }}
                          isDisabled
                        />

                        {isArrayOrObject &&
                          modalData.fieldList?.map((field, index) => {
                            const selectOptions = (field?.fields ?? []).map(
                              (e) => ({
                                ...e,
                                label: e.name,
                                value: e.path,
                              })
                            );
                            // console.log(modalData.fieldList);
                            return field.fields.length > 0 ? (
                              <>
                                <div>
                                  <br />
                                  <ReactSelect
                                    classNamePrefix="select"
                                    /* value={field.fields.find((e) => {
                                    return (
                                      modalData.selectedChild?.path ?? ""
                                    ).includes(e.path);
                                  })} */
                                    value={selectOptions.find((e) => {
                                      return (
                                        modalData.selectedChild?.path ??
                                        "no path"
                                      ).startsWith(e.path);
                                    })}
                                    /*  isOptionSelected={selectOptions.find((e) => {
                                    return (
                                      modalData.selectedChild?.path ?? ""
                                    ).startsWith(e.path);
                                  })} */
                                    options={selectOptions}
                                    onChange={(e) => {
                                      if (!e?.value) return;

                                      groupBySelectRef?.current?.clearValue();
                                      aggregateFunctionRef?.current?.clearValue();
                                      setTimeout(() => {
                                        handleLevelClick(e, index + 1);
                                      }, 10);
                                    }}
                                    isDisabled
                                  />
                                </div>
                              </>
                            ) : (
                              <div></div>
                            );
                          })}
                      </div>
                    </div>
                    <div className="col-12 col-lg-6 col-md-6">
                      <div className="form-group">
                        <label for="" className="form-label">
                          {t(
                            "fieldConfiguration.fieldConfigPopup.fieldTypeLabel"
                          )}
                          <sup>
                            <i
                              className="fa-solid fa-star-of-life"
                              style={{
                                color: "red",
                                fontSize: "5px",
                                marginBottom: "5px",
                              }}
                            ></i>
                          </sup>
                        </label>
                        <ReactSelect
                          ref={fieldTypeSelectRef}
                          classNamePrefix="select"
                          options={fieldTypeOptions}
                          value={fieldTypeOptions.find(
                            (e) =>
                              e.value ===
                              getWidgetTypeBasedOnFieldTypeNoAny(
                                modalData?.fieldType
                              )
                          )}
                          onChange={(e) => {
                            setModalData((prev) => ({
                              ...prev,
                              fieldType: e?.value,
                            }));
                          }}
                          // isDisabled
                          /* isDisabled={
                          ["array", "object"].includes(modalData?.fieldType) ||
                          modalData?.aggregateFunctionId !== -1
                        } */
                          isDisabled={
                            ["array", "object"].includes(
                              selectedChildOrCurrentFieldType
                            ) ||
                            modalData.isPrimarySecondary ||
                            /* (modalData?.aggregateFunctionId &&
                              modalData?.aggregateFunctionId !== -1) || */
                            (!modalData?.dropped && disableForCompany) || aggregateList.find((e) => e.aggregateFunctionId === modalData?.aggregateFunctionId)?.code === "count"
                          }
                        />
                      </div>

                      {/* {["text", "number", "date"].includes(currentFieldType) && ( */}
                      {modalData?.fieldOptionsEnable && (
                        <ChipInput
                          label={t(
                            "fieldConfiguration.fieldConfigPopup.fieldOptionsLabel"
                          )}
                          renderCheckbox
                          hideInput
                          checked={modalData?.fieldOptionsEnable}
                          // disabled={!modalData?.fieldOptionsEnable}
                          disabled={true}
                          disabledCheck={true}
                          onCheckChanged={(val) => {
                            setModalData((prev) => ({
                              ...prev,
                              fieldOptionsEnable: val,
                            }));
                          }}
                          chipList={modalData?.fieldOptions ?? []}
                          onChangeValue={(chipList) => {
                            setModalData((prev) => ({
                              ...prev,
                              fieldOptions: chipList,
                            }));
                          }}
                        />
                      )}

                      {/* {currentFieldType === "dropdown" && (
                      <Form.Group
                        className="text-start form-group"
                        controlId="formEmail"
                      >
                        <Form.Label>Field Options</Form.Label>
                        <Form.Control
                          className="form-control"
                          placeholder="Comma separated options"
                          name="fieldOptions"
                          type="text"
                          disabled={true}
                          value={modalData?.fieldOptions}
                          onChange={(e) => {
                            setModalData((prev) => ({
                              ...prev,
                              fieldOptions: e.target.value,
                            }));
                          }}
                          required
                        />
                      </Form.Group>
                    )} */}
                    </div>
                    {/* <div className="col-12 col-lg-6 col-md-6">
                    <div className="form-group">
                      <label for="" className="form-label">
                        Index Number
                      </label>
                      <input
                        disabled
                        type="text"
                        name="indexNumber"
                        value={modalData?.indexNumber}
                        onChange={(e) => {
                          setModalData({
                            ...modalData,
                            indexNumber: e.target.value.trimStart(),
                          });
                        }}
                        className="form-control"
                        placeholder="Index Number"
                      />
                    </div>
                  </div> */}

                    <div className="col-6">
                      <div className="form-group">
                        <label for="" className="form-label">
                          {t(
                            "fieldConfiguration.fieldConfigPopup.aggregateFunctionLabel"
                          )}
                        </label>
                        <ReactSelect
                          ref={aggregateFunctionRef}
                          classNamePrefix="select"
                          options={aggregateOptions}
                          value={aggregateOptions.find(
                            (e) => e.value === modalData.aggregateFunctionId
                          )}
                          /* isDisabled={["array", "object"].includes(
                              modalData.selectedChild?.type ?? ""
                            )} */
                          // isDisabled
                          isOptionDisabled={(option, selected) => {
                            if (modalData.fieldType === "date") {
                              return (
                                option.code === "sum" || option.code === "avg"
                              );
                            }

                            return selectedChildOrCurrentFieldType === "array"
                              ? option.code !== "count" && option.value !== -1
                              : false;
                          }}
                          isDisabled={
                            ["object"].includes(
                              selectedChildOrCurrentFieldType
                            ) ||
                            modalData.isPrimarySecondary ||
                            (!modalData?.dropped && disableForCompany)
                          }
                          onChange={(e) => {
                            // if aggregate function is selected then show number as field type
                            // if aggregate function is selected to none then set existing field type
                            let fieldType = selectedChildOrCurrentFieldType;

                            if (e?.value && e?.value !== -1) {
                              fieldType = "number";
                            }

                            if (modalData?.fieldType === "date") {
                              fieldType = "date";
                            }

                            setModalData((prev) => ({
                              ...prev,
                              aggregateFunctionId: e?.value ?? -1,
                              fieldType,
                            }));
                          }}
                        />
                      </div>
                    </div>

                    {/* here only fields will be displayed which is not array or object to group by */}
                    <div className="col-6">
                      <div className="form-group">
                        <label for="" className="form-label">
                          {t(
                            "fieldConfiguration.fieldConfigPopup.groupByLabel"
                          )}
                        </label>
                        <ReactSelect
                          ref={groupBySelectRef}
                          classNamePrefix="select"
                          value={groupBySelectOptions.find(
                            (e) => e.path === modalData.selectedGroupByPath
                          )}
                          options={groupBySelectOptions.filter(
                            (e) =>
                              e.path !== modalData.pathName &&
                              !["array", "object"].includes(e.type)
                          )}
                          isOptionSelected={(option) =>
                            option.path === modalData.selectedGroupByPath
                          }
                          onChange={(e) => {
                            setModalData((prev) => ({
                              ...prev,
                              selectedGroupByPath: e?.value,
                            }));
                          }}
                          isDisabled={
                            modalData.isPrimarySecondary ||
                            !modalData?.groupByList ||
                            modalData.aggregateFunctionId === -1 ||
                            (!modalData?.dropped && disableForCompany)
                          }
                        />
                      </div>
                    </div>
                  </div>

                  {/* <div className="mt-4 inactive-builder"> */}
                  <div
                    className={`mt-4 ${(groupBySelectOptions.length > 0 &&
                      modalData?.dropped &&
                      !modalData.isPrimarySecondary) ||
                      !disableForCompany
                      ? ""
                      : "inactive-div"
                      }`}
                  >
                    <div className="row border-bottom pb-2 mb-3 align-items-center justify-content-between">
                      <div className="col-auto">
                        <span
                          className="fs-17 fw-bold text-primary"
                          style={{
                            borderColor: "#e8e8f7 !important",
                          }}
                        >
                          {t(
                            "fieldConfiguration.fieldConfigPopup.whereClauseTitle"
                          )}
                        </span>
                      </div>

                      <div className="col-auto">
                        <PrimaryButton
                          disabled={
                            (modalData?.query ?? []).filter((e) => !e.isDeleted)
                              .length <= 0
                          }
                          title={t("CommonKeys.reset")}
                          icon="fa-solid fa-arrows-rotate"
                          onClick={() => {
                            setConfirmQueryReset(true);
                          }}
                        />
                      </div>
                    </div>

                    {/* <Query
                      // {...config}
                      {...schemaBasedRuleConfig}
                      value={state.tree}
                      onChange={onChange}
                      renderBuilder={renderBuilder}
                    /> */}

                    <AICCRuleBuilder
                      language={t("aiccRuleBuilder", {
                        returnObjects: true,
                      })}
                      // allowValueSource
                      allowArithmetic
                      query={modalData?.query ?? []}
                      fields={groupBySelectOptions}
                      // fieldsWithGroup={ruleBuilderFields}
                      // useFieldGroup
                      onChangeQuery={(myQuery) => {
                        setModalData((prev) => ({
                          ...prev,
                          query: myQuery,
                        }));
                      }}
                    />
                  </div>
                  <div className="text-end mt-3">
                    {modalData?.queryString && (
                      <PrimaryButton
                        title={t("CommonKeys.view")}
                        icon="fa-regular fa-eye"
                        className="me-3"
                        onClick={() => {
                          setShowQuery(true);
                        }}
                      />
                    )}
                    <PrimaryButton
                      title={t("CommonKeys.saveButtonTitle")}
                      icon="fa-regular fa-floppy-disk"
                      disabled={isSaveDisabled}
                      onClick={() => {
                        setShowModal(false);
                        handleSave({
                          ...modalData,
                          // isUpdated: originalData !== JSON.stringify(modalData),
                          isUpdated: true,
                        });
                      }}
                    />
                    <DangerButton
                      title={t("CommonKeys.cancelButtonTitle")}
                      className="ms-3"
                      icon={"fa-solid fa-xmark"}
                      onClick={onCancel}
                    />
                  </div>
                </>
              )}
              {/* <div className="text-end mt-3">
                <button
                  onClick={() => handleSave(modalData)}
                  className={"btn btn-fill"}
                >
                  Save
                </button>
              </div> */}
            </div>
          </div>
        }
        // cancelTitle={t("CommonKeys.no")}
        /* onOk={() => {
          setShowModal(false);
          handleSave(modalData);
        }}
        onCancel={() => {
          setShowModal(false);
        }} */
        handleClose={onCancel}
      />

      <CustomModal
        title={t(
          "fieldConfiguration.fieldConfigPopup.dropFieldCancelledPopup.title"
        )}
        body={
          <b className="text-danger">
            {t(
              "fieldConfiguration.fieldConfigPopup.dropFieldCancelledPopup.message"
            )}
          </b>
        }
        backdropClassName="top-modal"
        show={confirmCancel}
        centered
        onOk={() => {
          setShowModal(false);
          setConfirmCancel(false);
        }}
        onCancel={() => {
          setConfirmCancel(false);
        }}
      />

      <CustomModal
        size={"xl"}
        backdropClassName="top-modal"
        show={showQuery}
        handleClose={() => setShowQuery(false)}
        title={t("fieldConfiguration.fieldConfigPopup.queryStringPopup.title")}
        body={modalData.queryString}
        centered
        okTitle={t("CommonKeys.okButtonTitle")}
        onOk={() => setShowQuery(false)}
      />

      <CustomModal
        show={confirmQueryReset}
        backdropClassName="top-modal"
        handleClose={() => setConfirmQueryReset(false)}
        title={t("createRuleEngine.breadCrumb.title")}
        body={
          <span className="text-danger fw-bold">
            {t("createRuleEngine.resetRuleBuilder")}
          </span>
        }
        centered
        okTitle={t("CommonKeys.yes")}
        onOk={() => {
          const temp = Array.from(modalData.query);

          setModalData((prev) => ({
            ...prev,
            query: temp
              .filter((e) => e.expressionId)
              .map((e) => ({ ...e, isDeleted: true })),
          }));
          setConfirmQueryReset(false);
        }}
        cancelTitle={t("CommonKeys.no")}
        onCancel={() => {
          setConfirmQueryReset(false);
        }}
      />
    </>
  );
};

export default CommonFieldConfig;
