import { AICCBuilderInitialConfig } from "../../lib/aiccRuleBuilder/constants/AICCBuilderConfig";
import AICCRuleBuilderUtils from "../../lib/aiccRuleBuilder/utils/AICCRuleBuilderUtils";
import {
  getWidgetTypeBasedOnFieldType,
  groupByJson,
  truncateString,
} from "../coreUtils";

/* eslint-disable import/no-anonymous-default-export */
export default {
  fieldConfigToRuleFields(list = [], schemaList) {
    const groupedJson = groupByJson(list, "groupId");

    const fields = {};

    // console.log(groupedJson);

    Object.keys(groupedJson).forEach((key, index) => {
      const subfields = {};

      const finalArr = groupedJson[key] ?? [];

      finalArr.forEach((field) => {
        subfields[field.customFieldsId + "." + field.contextPath] = {
          // subfields[field.contextPath] = {
          label: field.name,
          path: field.customFieldsId,
          customName: field.customFieldsId,
          /* operators: [
            "equal",
            "not_equal",
            "less_or_equal",
            "greater_or_equal",
            "between",
            "not_between",
          ], */
          type: getWidgetTypeBasedOnFieldType(field?.fieldType ?? ""),
        };
      });

      // console.log("=======", key, finalArr.length, subfields);
      // console.log("=======", schemaList);

      const foundedSchema = schemaList.find(
        (e) => e.jsonId === groupedJson[key][0]?.schemaId
      );

      fields["" + groupedJson[key][0]?.groupId] = {
        // fields[key] = {
        // fields[index] = {
        label:
          truncateString(foundedSchema?.jsonFileSchemaName ?? "", 20) +
          " | " +
          groupedJson[key][0].customFieldGroup,
        type: "!group",
        subfields: subfields,
      };
    });

    // console.log(fields)

    return fields;
  },
  fieldConfigChildToRuleFields(list = []) {
    const groupedJson = groupByJson(list, "path");

    const fields = {};

    // console.log(groupedJson);

    Object.keys(groupedJson).forEach((key) => {
      // const subfields = {};

      /* (groupedJson[key] ?? []).forEach((field) => {
        subfields[field.contextPath] = {
          label: field.name,
          operators: [
            "equal",
            "not_equal",
            "less_or_equal",
            "greater_or_equal",
            "between",
            "not_between",
          ],
          type: "number",
        };
      }); */

      fields[key] = {
        label: groupedJson[key][0]?.name,
        type: getWidgetTypeBasedOnFieldType(groupedJson[key][0]?.type),
        /* fieldSettings: {
          min: 0,
        },
        valueSources: ['value'],
        preferWidgets: ['number'], */
      };
    });

    return fields;
  },

  aiccRBSchemaToFields(list = []) {
    const groupedJson = groupByJson(list, "path");

    const fields = [];

    Object.keys(groupedJson).forEach((key, index) => {
      fields.push({
        label: groupedJson[key][0]?.name,
        type: getWidgetTypeBasedOnFieldType(groupedJson[key][0]?.type),
        value: groupedJson[key][0]?.path,
      });
    });

    return fields;
  },
  aiccRBFieldConfigToGroupedFields(list = [], schemaList = []) {
    const groupedJson = groupByJson(
      list.filter((e) => !e.isPrimarySecondary),
      "groupId"
    );
    const allFields = [];

    Object.keys(groupedJson).forEach((key, index) => {
      const subFields = [];

      const finalArr = groupedJson[key] ?? [];

      finalArr.forEach((field) => {
        const fieldOptions = field.fieldOptions ?? "";
        const fieldOptionsEnable = field.fieldOptionsEnable ?? false;

        subFields.push({
          label: field.name,
          value: field.customFieldsId,
          path: field.customFieldsId,
          fieldOptions: fieldOptionsEnable
            ? fieldOptions
              .trim(",")
              .split(",")
              .map((e) => {
                return {
                  label: e.trim(),
                  value: e.trim(),
                };
              })
            : undefined,
          customName: field.customFieldsId,
          groupId: groupedJson[key][0]?.groupId,
          type: getWidgetTypeBasedOnFieldType(field?.fieldType ?? ""),
        });
      });

      const foundedSchema = schemaList.find(
        (e) => e.jsonId === groupedJson[key][0]?.schemaId
      );

      allFields.push({
        label: foundedSchema
          ? `${truncateString(
            foundedSchema?.jsonFileSchemaName ?? "",
            13
          )} | ${groupedJson[key][0]?.customFieldGroup}`
          : groupedJson[key][0]?.customFieldGroup,
        value: groupedJson[key][0]?.groupId,
        groupId: groupedJson[key][0]?.groupId,
        subFields,
      });
    });

    return allFields;
  },

  aiccRBQueryToWhereClause(list = [], fieldConfig = false, mappedFieldJson, useArrayExp) {
    const finalList = list.filter((e) => {
      const initialCondition =
        e.column && e.operator && (e.noInput || e.operator.startsWith("boolean_") ? true : e.expectedValue);

      if (e.operator && e.operator.endsWith("_between")) {
        if (e.valueSource === "field") {
          const listOfOperand = e.expectedValue
            .split(",")
            .filter((e) => e.trim());

          return initialCondition && listOfOperand.length === 2;
        }

        const listOfOperand = e.expectedValue
          .split(",")
          .filter((e) => e.trim());

        return initialCondition && listOfOperand.length === 2;
      }

      return initialCondition;
    });

    const isArrayField = (path) => {
      while (path.length > 0) {
        if (mappedFieldJson[path] && mappedFieldJson[path].type === "array") {
          return true;
        }

        path = path.substring(0, path.lastIndexOf("."));
      }

      return false;
    };

    const mapRule =
      (avoidOrder = false, myList) =>
        (e, index) => {
          const ariOp = (e.selectedRightArOp ?? "").split(",");
          const ariOpVal = (e.rightArOpValue ?? "").split(",");

          const foundedOperator = AICCBuilderInitialConfig.operators.find(
            (item) => item.value === e.operator
          );

          let finalValue = e.expectedValue;

          if (
            foundedOperator.type === "boolean" &&
            (e.expectedValue === undefined || e.expectedValue === "")
          ) {
            finalValue = false;
          }
          if (foundedOperator.type === "datetime") {
            finalValue = e.expectedValue.replace("T", " ");
          }

          if (foundedOperator.value.endsWith("_in")) {
            if (
              e.expectedValue.trim().charAt(e.expectedValue.length - 1) === ","
            ) {
              finalValue = e.expectedValue.substring(
                0,
                e.expectedValue.length - 1
              );
            }
          }

          const rightLeftArithmetic =
            e.rightLeftArithmetic && ariOp[0] && ariOpVal[0]
              ? `${ariOp[0]} ${ariOpVal[0]}`
              : "";

          const rightRightArithmetic =
            e.rightRightArithmetic && ariOp[1] && ariOpVal[1]
              ? `${ariOp[1]} ${ariOpVal[1]}`
              : "";

          const isExpressionArray = isArrayField(e.column)

          // console.log("isItArray", isItArray);

          const obj = {
            id: e.expressionId ?? 0,
            leftOperand: e.column,

            // as requested by backend developer we have to replace decimal_ to integer_ while selected operator has type equal to decimal.
            // the reason behind this is to avoid more changes in backend as integer and decimal will have same query string generation logic.
            operator:
              foundedOperator?.type === "decimal"
                ? e.operator.replace("decimal_", "number_")
                : e.operator,
            // to handle query string generation for integer and decimal we are sending type of operator to backend.
            numberType: foundedOperator?.type,

            rightOperand: finalValue,
            isDeleted: e.isDeleted ?? false,
            isActive: e.isActive ?? true,

            isLeftArithmetic: e.leftArithmetic ?? false,
            isLeftFieldType: e.leftArOpValueSource === "field",
            leftOpArithmetic:
              e.leftArOpValue && e.selectedLeftArOp && e.leftArOpValue
                ? `${e.selectedLeftArOp} ${e.leftArOpValue}`
                : "",

            isFirstRightArithmetic: e.rightLeftArithmetic ?? false,
            rightFirstOpArithmetic: e.rightLeftArithmetic
              ? rightLeftArithmetic
              : "",

            isSecondRightArithmetic: e.rightRightArithmetic ?? false,
            rightSecondOpArithmetic: e.rightRightArithmetic
              ? rightRightArithmetic
              : "",

            isFieldType: e.valueSource === "field",
            midOperator:
              index === myList.length - 1
                ? undefined
                : (e.midOperator ? e.midOperator : "and").toUpperCase(),
          };

          if (useArrayExp) {
            obj.isExpressionArray = isExpressionArray
          }

          if (!avoidOrder) {
            obj.expressionOrder = index + 1;
          }
          if (fieldConfig) {
            obj.customFieldsId = e.customFieldsId ?? 0;
          }
          return obj;
        };

    const deleted = finalList.filter((e) => e.isDeleted);
    const nonDeleted = finalList.filter((e) => !e.isDeleted);
    // console.log(finalList);

    return [
      ...nonDeleted.map(mapRule(undefined, nonDeleted)),
      ...deleted.map(mapRule(true, deleted)),
    ];
  },
  aiccRBWhereClauseToQuery(list = [], fieldConfig = false) {
    return list.map((e, index) => {
      // as backend wants to go with replacing value of selected operator by replacing decimal_ to the integer_ to save time to work on that stuff we are using this logic.
      // it replace integer_ to decimal_ so that previously selected decimal operator get displayed correctly.
      const finalOperator = e.numberType === "decimal" ? e.operator.replace("number_", "decimal_") : e.operator

      const foundedOperator = AICCBuilderInitialConfig.operators.find(
        (item) => item.value === finalOperator
      );

      const rightFirstOpDetails = (e.rightFirstOpArithmetic ?? "").split(" ");
      const rightSecondOpDetails = (e.rightSecondOpArithmetic ?? "").split(" ");

      const rightArithmeticOp = [];

      if (e.isFirstRightArithmetic) {
        rightArithmeticOp.push(rightFirstOpDetails[0] ?? "");
      }
      if (e.isSecondRightArithmetic) {
        rightArithmeticOp.push(rightSecondOpDetails[0] ?? "");
      }

      const rightArithmeticOpVal = [];

      if (e.isFirstRightArithmetic) {
        rightArithmeticOpVal.push(rightFirstOpDetails[1] ?? "");
      }
      if (e.isSecondRightArithmetic) {
        rightArithmeticOpVal.push(rightSecondOpDetails[1] ?? "");
      }

      return {
        id: AICCRuleBuilderUtils.getUUID(),
        expressionId: e.id,
        column: fieldConfig ? e.leftOperand : parseInt(e.leftOperand),
        operator: finalOperator,
        numberType: e.numberType,
        group: fieldConfig
          ? e.leftOperandGroupId
          : parseInt(e.leftOperandGroupId),
        noInput: foundedOperator?.noInput,
        expectedValue: foundedOperator.type === "datetime" ? e.rightOperand.replace(" ", "T") : e.rightOperand,
        customFieldsId: e.customFieldsId ?? undefined,
        valueSource: e.isFieldType ? "field" : "value",
        valueFieldGroup: e.isFieldType
          ? e.operator.endsWith("_between")
            ? `${e.rightOperandGroupIdFirst},${e.rightOperandGroupIdSecond}`
            : e.rightOperandGroupIdFirst
          : "",
        isActive: e.isActive ?? true,
        leftArOpValueSource: e.isLeftFieldType ? "field" : "value",
        leftArOpFieldGroup: e.leftOpGroupId ?? "",
        leftArithmetic: e.isLeftArithmetic ?? false,
        selectedLeftArOp: (e.leftOpArithmetic ?? "").split(" ")[0] ?? "",
        leftArOpValue: (e.leftOpArithmetic ?? "").split(" ")[1] ?? "",

        rightLeftArithmetic: e.isFirstRightArithmetic ?? false,

        rightRightArithmetic: e.isSecondRightArithmetic ?? false,

        selectedRightArOp: rightArithmeticOp.join(","),
        rightArOpValue: rightArithmeticOpVal.join(","),

        midOperator:
          index === list.length - 1
            ? undefined
            : (e.midOperator ? e.midOperator : "and").toLowerCase(),
      };
    });
  },
};
