import React, { useState } from "react";
import PrimaryButton from "./Form/Buttons/PrimaryButton";
import AiccBreadCrumb from "./common/AiccBreadCrumb";
import { getPathPrefix } from "../utils/coreUtils";
import { t } from "i18next";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import TextArea from "./Form/Inputs/TextArea";
import TextInput from "./Form/Inputs/TextInput";
// import 
function ContactAicc() {


  const initialValues = {
    subject: "",
    message: ""
  }
  const formValidationSchema = Yup.object({
    subject: Yup.string().required(t("contactPage.form.subject.required")),
    message: Yup.string().required(t("contactPage.form.message.required"))
  })

  const submitHandler = async (values, action) => {
    console.log("====>", values);
  }
  return (
    <div>
      <div className="container-scroller">
        {/* <!-- partial --> */}
        <div className="container-fluid page-body-wrapper p-0">
          {/* <!-- partial --> */}
          <div className="main-panel">
            <div className="content-wrapper">
              {/* <!-- breadcrumb-start --> */}
              <AiccBreadCrumb
                homeLink={getPathPrefix("dashboard")}
                title={t("contactPage.breadCrumb.title")}
                items={t("contactPage.breadCrumb.items", {
                  returnObjects: true,
                })}
              />
              {/* <!-- breadcrumb-end --> */}
              <div className="col-12 col-lg-9 col-md-12 mx-auto">
                <div className="card main-card-table">
                  <div className="card-header">
                    <div className="row align-items-center">
                      <div className="col-12">
                        <div className="card-label mb-0 d-flex">
                          <i className="fa-solid fa-square-phone me-3 fs-4 text-primary"></i>{" "}
                          {t("contactPage.cardTitle")}
                        </div>
                      </div>
                    </div>
                  </div>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={formValidationSchema}
                    onSubmit={submitHandler}
                  >
                    {
                      ({
                        values,
                        errors,
                        handleSubmit,
                        handleBlur,
                        handleChange,
                      }) =>
                      (
                        <Form onSubmit={handleSubmit}>
                          <div className="card-body">
                            {/* <div className="form"> */}
                            <div className="row">
                              <div className="col-lg-8 col-md-8">
                                <div className="row">
                                  <div className="col-lg-12 col-md-12">

                                    <Field
                                      component={TextInput}
                                      label={t("contactPage.form.subject.label")}
                                      // className="form-control"
                                      id="subject"
                                      placeholder={t("contactPage.form.subject.placeholder")}
                                      name="subject"
                                    />

                                    <Field
                                      component={TextArea}
                                      label={t("contactPage.form.message.label")}
                                      id="message"
                                      name="message"
                                      placeholder={t("contactPage.form.message.placeholder")}
                                      style={{
                                        minHeight: "150px",
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="d-flex justify-content-end">
                                  <div className="form-group">
                                    <PrimaryButton
                                      type="submit"
                                      title={t("CommonKeys.submitButtonTitle")}
                                      icon="fa-regular fa-paper-plane me-2"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-4 col-md-4">
                                <div className="contact-wrapper">
                                  <ul className="contact-info">
                                    <li>
                                      <i className="fa-solid  fa-envelope me-2 text-primary"></i>
                                      <a href={`mailto:${t("contactPage.aiccContactInfo.emailAddress")}`}>{t("contactPage.aiccContactInfo.emailAddress")}</a>
                                    </li>
                                    <li>
                                      <i className="fa-solid fa-phone me-2 text-primary"></i>
                                      <a href={`mailto:${t("contactPage.aiccContactInfo.phoneNumber")}`}>{t("contactPage.aiccContactInfo.phoneNumber")}</a>
                                    </li>
                                    <li>
                                      <i className="fa-solid fa-location-dot me-2 text-primary"></i>
                                      <a href="#">{t("contactPage.aiccContactInfo.location")}</a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            {/* </div> */}
                          </div>
                        </Form>
                      )
                    }

                  </Formik>
                </div>
                {/* <!-- content-wrapper ends --> */}
                {/* <!-- partial:partials/_footer.html --> */}
                <footer className="footer">
                  <div className="d-sm-flex justify-content-center">
                    <p className="mb-0">
                      AI Cost Control @2022 | Developed By :{" "}
                      <a href="#" target="_blank">
                        Om Software
                      </a>
                    </p>
                  </div>
                </footer>
                {/* <!-- partial --> */}
              </div>
              {/* <!-- main-panel ends --> */}
            </div>
            {/* <!-- page-body-wrapper ends --> */}
          </div>
          {/* <!-- container-scroller --> */}
        </div>
      </div>
    </div >
  );
}

export default ContactAicc;
