import { Field, Form, Formik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";

import { toast } from "react-toastify";
import CenterLoading from "../../components/common/CenterLoading";

import PrimaryButton from "../../components/Form/Buttons/PrimaryButton";
import PasswordInput from "../../components/Form/Inputs/PasswordInput";
import TextInput from "../../components/Form/Inputs/TextInput";
import profilePic from "../../components/user-icon.png";
import companyUserApi from "../../services/apis/companyUserApi";
import { convertBase64, getPathPrefix } from "../../utils/coreUtils";
import { NameValidationNoSpace, PasswordRegex } from "../../utils/RegexUtils";
import { jsonToFormData } from "../../utils/ServicesUtils";
import { getPhoneNumberJson } from "../company_users/utils/core_utils";
import CusPhoneInput from "../../components/Form/Inputs/CusPhoneInput";
import SelectInput from "../../components/Form/Inputs/SelectInput";
import openApi from "../../services/apis/openApi";
import { useDispatch } from "react-redux";
import { companyUserDetailsAction } from "../company_users/redux/actions/company_user_action";
import { useTranslation } from "react-i18next";
import routeUserApi from "../../services/apis/routeUserApi";
import ToastUtils from "../../utils/ToastUtils";
import { useSelector } from "react-redux";

export const USER_FORM_OPTION = {
  UPDATE_ADMIN: "ua",
  UPDATE_ADMIN_USER: "uau",
  UPDATE_USER: "uu",
  UPDATE_ROUTING_USER: "uru",
  CREATE_USER: "cu",
  VIEW_USER: "vu",
  VIEW_ROUTING_USER: "vru",
};

export default function UpdateUser() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();

  const companyUserDetailsData = useSelector(
    (state) => state.companyUserReducer.companyUserDetailsData
  );

  const [t, i18n] = useTranslation();

  const roleOptions = [
    {
      label: t("userRole.companyAdmin"),
      value: "CA",
    },
    {
      label: t("userRole.companyUser"),
      value: "CU",
    },
    {
      label: t("userRole.routingUser"),
      value: "RU",
    },
  ];

  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const [companyDetails, setCompanyDetails] = useState();
  const [fileToUpload, setFileToUpload] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [configList, setConfigList] = useState([]);

  const currentUserId = useMemo(
    () => localStorage.getItem("companyUserId"),
    []
  );

  const companyUserId = useMemo(() => params?.userId, [params?.userId]);

  // screen type to show form
  const screenType = useMemo(
    () => searchParams.get("t") ?? "CU",
    [searchParams]
  );

  const pageTitle = useMemo(() => {
    if (screenType === USER_FORM_OPTION.UPDATE_ADMIN) {
      return t("createUserForm.updateProfileTitle");
    }

    if (
      screenType === USER_FORM_OPTION.VIEW_USER ||
      screenType === USER_FORM_OPTION.VIEW_ROUTING_USER
    ) {
      return t("createUserForm.viewUserTitle");
    }

    if (
      screenType === USER_FORM_OPTION.UPDATE_USER ||
      screenType === USER_FORM_OPTION.UPDATE_ADMIN_USER ||
      screenType === USER_FORM_OPTION.UPDATE_ROUTING_USER
    ) {
      return t("createUserForm.updateUserTitle");
    }

    return t("createUserForm.createUser");
  }, [screenType]);

  const getDataStreamConfig = async () => {
    try {
      const { data } = await openApi.getDataStreamConfig();
      if (typeof data !== "string") {
        setConfigList(data ?? []);
      }
    } catch (error) {}
  };

  const getCompanyUserDetailsById = async () => {
    setIsLoading(true);
    try {
      const companyUserDetails = await companyUserApi.getCompanyUserDetailsById(
        {
          companyUserId: companyUserId ?? localStorage.getItem("companyUserId"),
        }
      );

      setCompanyDetails(companyUserDetails.data);
    } catch (error) {}
    setIsLoading(false);
  };

  const getRoutingUserDetailsById = async () => {
    setIsLoading(true);
    try {
      const detail = await routeUserApi.getRoutingUserDetails(companyUserId);

      console.log(detail.data.dataStreamConfigId);

      setCompanyDetails({
        ...detail.data.response,
        userRoleType: "RU",
        isActive:
          detail.data.response?.isActive ?? detail.data.response?.active,
        dataStreamConfigIds: detail.data.response.dataStreamConfigId ?? [],
      });
    } catch (error) {}
    setIsLoading(false);
  };

  useEffect(() => {
    /* if (screenType !== USER_FORM_OPTION.CREATE_USER) {
      getCompanyUserDetailsById();
    } */
    if (
      screenType === USER_FORM_OPTION.VIEW_ROUTING_USER ||
      screenType === USER_FORM_OPTION.UPDATE_ROUTING_USER
    ) {
      getRoutingUserDetailsById();
    } else if (screenType !== USER_FORM_OPTION.CREATE_USER) {
      getCompanyUserDetailsById();
    }
    getDataStreamConfig();
  }, [screenType]);

  const updateOrCreateNormalUser = async (values) => {
    try {
      const userPhone = getPhoneNumberJson(values.mobileNumber);

      const formData = jsonToFormData({
        ...values,

        dataStreamConfigId: undefined,

        mobileNumber: userPhone.nationalNumber,
        countryCode: userPhone.code,

        password: values.password ? values.password : undefined,
        // userRoleType: companyDetails?.userRoleType,
        companyUserId: companyUserId
          ? companyUserId
          : screenType === USER_FORM_OPTION.UPDATE_ADMIN
          ? localStorage.getItem("companyUserId")
          : undefined,

        profilePictureUrl: undefined,
        confirmPassword: undefined,
        profilePicture: fileToUpload,
        accessUrl: localStorage.getItem("companyDomain"),
        userCode: undefined,
        isDeleted: false
      });

      if (values.userRoleType === "CU") {
        values.dataStreamConfigId.forEach((e) => {
          formData.append("dataStreamConfigId", parseInt(e));
        });
      }

      if (screenType === USER_FORM_OPTION.UPDATE_ADMIN) {
        const response = await companyUserApi.updateCompanyProfile(formData);

        await dispatch(
          companyUserDetailsAction(localStorage.getItem("companyUserId"))
        ).then((response) => {
          localStorage.setItem(
            "userDetails",
            JSON.stringify({
              userRoleType: response?.userRoleType,
              firstName: response?.firstName,
              lastName: response?.lastName,
              profilePicture: response?.profilePicture,
            })
          );
        });
        // toast.success(t("createUserForm.profileUpdatedMessage"));
        if (response.data.status != 200) {
          ToastUtils.serverError(t(`statusCodes.${response?.data?.code}`));
        } else {
          navigate(getPathPrefix("dashboard"));
          toast.success(t(`statusCodes.${response?.data?.code}`));
        }
      } else if (
        screenType === USER_FORM_OPTION.UPDATE_USER ||
        screenType === USER_FORM_OPTION.UPDATE_ADMIN_USER
      ) {
        const response = await companyUserApi.updateCompanyProfile(formData);
        // toast.success(t("createUserForm.userUpdatedMessage"));
        if (response.data.status != 200) {
          ToastUtils.serverError(t(`statusCodes.${response?.data?.code}`));
        } else {
          navigate(getPathPrefix("company-user/user-list"));
          toast.success(t(`statusCodes.${response?.data?.code}`));
        }
      } else {
        const response = await companyUserApi.saveCompanyUser(formData);
        // toast.success(t("createUserForm.userCreatedMessage"));
        if (response.data.status != 200) {
          ToastUtils.serverError(t(`statusCodes.${response?.data?.code}`));
        } else {
          navigate(getPathPrefix("company-user/user-list"));
          toast.success(t(`statusCodes.${response?.data?.code}`));
        }
      }
    } catch (error) {}
  };

  const updateOrCreateRoutingUser = async (values) => {
    try {
      const data = {
        userCode: values.userCode,
        firstName: values.firstName,
        lastName: values.lastName,
        dataStreamConfigId: values.dataStreamConfigId,
        userRoleType: values.userRoleType,
        profilePicture: fileToUpload,
        companyId: localStorage.getItem("companyId"),
        isActive: values.isActive,
        isDeleted: false,
      };

      if (screenType === USER_FORM_OPTION.CREATE_USER) {
        let response = await routeUserApi.saveRoutingUser(data);
        // toast.success(t("createUserForm.userCreatedMessage"));
        if (response?.data?.response?.body?.status != 200) {
          ToastUtils.serverError(
            t(`statusCodes.${response?.data?.response?.body?.code}`)
          );
        } else {
          toast.success(
            t(`statusCodes.${response?.data?.response?.body?.code}`)
          );
        }
      } else {
        let response = await routeUserApi.updateRoutingUser(
          {
            routeUserId: companyUserId,
          },
          data
        );
        // toast.success(t("createUserForm.userUpdatedMessage"));
        if (response?.data?.response?.body?.status != 200) {
          ToastUtils.serverError(
            t(`statusCodes.${response?.data?.response?.body?.code}`)
          );
        } else {
          toast.success(
            t(`statusCodes.${response?.data?.response?.body?.code}`)
          );
        }
      }

      navigate(getPathPrefix("company-user/routing-user-list"));
    } catch (error) {}
  };

  const saveCompanyProfile = async (values, actions) => {
    try {
      if (values.userRoleType !== "RU") {
        await updateOrCreateNormalUser(values);
      } else {
        updateOrCreateRoutingUser(values);
      }
    } catch (error) {}

    actions.setSubmitting(false);
  };

  const initialFormValues = {
    profilePicture: "",
    profilePictureUrl: companyDetails?.profilePicture ?? profilePic,
    userCode: companyDetails?.userCode ?? "",
    firstName: companyDetails?.firstName ?? "",
    lastName: companyDetails?.lastName ?? "",
    userRoleType:
      companyDetails?.userRoleType ??
      (searchParams.get("r") === "ru" ? "RU" : ""),
    emailId: companyDetails?.emailId ?? "",
    mobileNumber:
      (companyDetails?.countryCode ?? "") +
      (companyDetails?.mobileNumber ?? ""),
    password: "",
    confirmPassword: "",
    isActive: companyDetails?.isActive ?? companyDetails?.active ?? false,
    dataStreamConfigId: (companyDetails?.dataStreamConfigIds ?? []).map(
      (e) => "" + e
    ),
  };

  const formValidationSchema = Yup.object({
    userCode: Yup.string().label("First name"),
    userRoleType: Yup.string().label("User Role").required(),
    firstName: Yup.string()
      .label("First name")
      .matches(
        NameValidationNoSpace,
        t("createUserForm.firstName.matchesRegex")
      )
      .max(40, t("createUserForm.firstName.max40"))
      .required(t("createUserForm.firstName.required")),
    lastName: Yup.string()
      .label("Last name")
      .matches(NameValidationNoSpace, t("createUserForm.lastName.matchesRegex"))
      .max(40, t("createUserForm.lastName.max40"))
      .required(t("createUserForm.lastName.required")),
    emailId: Yup.string()
      .email(t("createUserForm.emailId.matchesRegex"))
      .label("Email")
      .when("userRoleType", {
        is: (userRoleType = "") => userRoleType !== "RU",
        then: Yup.string().required(t("createUserForm.emailId.required")),
      }),
    mobileNumber: Yup.string()
      .label("Mobile number")
      .when("userRoleType", {
        is: (userRoleType = "") => userRoleType !== "RU",
        then: Yup.string()
          .test(
            "mobile-number",
            t("createUserForm.mobileNo.matchesRegex"),
            (val) => {
              return getPhoneNumberJson(val).valid;
            }
          )
          .required(t("createUserForm.mobileNo.required")),
      }),
    password: Yup.string()
      .label("Password")
      .matches(PasswordRegex, t("createUserForm.password.matchesRegex")),
    confirmPassword: Yup.string()
      .label("Confirm password")
      .oneOf(
        [Yup.ref("password"), null],
        t("createUserForm.confirmPass.passwordNotMatch")
      )
      .matches(PasswordRegex, t("createUserForm.confirmPass.matchesRegex"))
      .when("password", {
        is: (password = "") => password.length > 0,
        then: Yup.string().required(
          t("createUserForm.confirmPass.matchWithPass")
        ),
      }),
    isActive: Yup.boolean(),
  });

  const inputEnabled =
    screenType !== USER_FORM_OPTION.VIEW_USER &&
    screenType !== USER_FORM_OPTION.VIEW_ROUTING_USER;

  const disableSchema =
    !inputEnabled ||
    screenType === USER_FORM_OPTION.UPDATE_ADMIN ||
    currentUserId == companyUserId;

  return (
    <div>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper p-0">
          <div className="main-panel">
            <div className="content-wrapper">
              <div className="page-breadcrumb d-none d-md-flex align-items-center mb-5">
                <div className="ps-3">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mb-0 p-0">
                      <li className="breadcrumb-item">
                        <a href="#">
                          <i className="fa-solid fa-house"></i>
                        </a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {pageTitle}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
              <div className="col-12 col-lg-9 col-md-12 mx-auto">
                <div className="card main-card-table">
                  <div className="card-header">
                    <div className="row align-items-center">
                      <div className="col-12">
                        <div className="card-label mb-0 d-flex">
                          <i className="fa-solid fa-user-group me-3 fs-5 text-primary"></i>{" "}
                          {pageTitle}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    {isLoading ? (
                      <CenterLoading />
                    ) : (
                      <Formik
                        initialValues={initialFormValues}
                        validationSchema={formValidationSchema}
                        onSubmit={saveCompanyProfile}
                        enableReinitialize
                        validateOnMount
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isValid,
                          isSubmitting,
                          dirty,
                          ...props
                        }) => (
                          <Form onSubmit={handleSubmit}>
                            <div>
                              {values.userRoleType !== "RU" && (
                                <div className="col-md-12">
                                  <div className="user-profile-image">
                                    <label
                                      htmlFor="profilePicture"
                                      className="cursor-pointer"
                                    >
                                      <img
                                        alt=""
                                        className="rounded-circle"
                                        src={
                                          values?.profilePictureUrl
                                            ? values?.profilePictureUrl
                                            : profilePic
                                        }
                                        style={{
                                          height: "100px",
                                          width: "100px",
                                        }}
                                      />
                                    </label>
                                    <label
                                      htmlFor="profilePicture"
                                      className="profile-upload-btn mb-1 me-1 d-flex align-items-center justify-content-center rounded-circle p-2 cursor-pointer"
                                    >
                                      <i
                                        className="fa-solid fa-camera"
                                        htmlFor="file"
                                      ></i>
                                      <input
                                        type="file"
                                        id="profilePicture"
                                        style={{ display: "none" }}
                                        name="profilePicture"
                                        accept="image/*"
                                        disabled={!inputEnabled}
                                        value={
                                          values.profilePicture?.filename ?? ""
                                        }
                                        onChange={async (e) => {
                                          if (e.target.files.length <= 0)
                                            return;

                                          setFileToUpload(
                                            e.target.files[0] ?? undefined
                                          );

                                          props.setFieldValue(
                                            "profilePictureUrl",
                                            await convertBase64(
                                              e.target.files[0]
                                            )
                                          );
                                          props.setFieldTouched(
                                            "profilePictureUrl"
                                          );
                                        }}
                                      />
                                    </label>
                                  </div>
                                </div>
                              )}

                              <div className="row">
                                <div className="col-md-12">
                                  <Field
                                    component={SelectInput}
                                    label={t("createUserForm.roleLabel")}
                                    placeholder={t(
                                      "createUserForm.rolePlaceholder"
                                    )}
                                    id="userRoleType"
                                    name="userRoleType"
                                    required
                                    options={roleOptions}
                                    isOptionDisabled={(option) => {
                                      if (
                                        location.pathname.includes(
                                          "update-user"
                                        )
                                      ) {
                                        return option.value === "RU";
                                      }

                                      return (
                                        companyUserDetailsData?.userRoleType ===
                                          "CU" && option.value !== "RU"
                                      );
                                    }}
                                    disabled={
                                      screenType ===
                                        USER_FORM_OPTION.UPDATE_ADMIN ||
                                      !inputEnabled ||
                                      screenType ===
                                        USER_FORM_OPTION.UPDATE_ROUTING_USER
                                    }
                                  />
                                </div>
                                {values.userRoleType === "RU" && (
                                  <div className="col-12">
                                    <Field
                                      component={TextInput}
                                      label={t("createUserForm.userCode.label")}
                                      id="userCode"
                                      name="userCode"
                                      placeholder={t(
                                        "createUserForm.userCode.placeholder"
                                      )}
                                      disabled={!inputEnabled}
                                      rightIcon={
                                        <i className="fa-solid fa-user"></i>
                                      }
                                    />
                                  </div>
                                )}
                                <div className="col-md-6">
                                  <Field
                                    component={TextInput}
                                    label={t("createUserForm.firstName.label")}
                                    id="firstName"
                                    name="firstName"
                                    placeholder={t(
                                      "createUserForm.firstName.placeholder"
                                    )}
                                    disabled={!inputEnabled}
                                    rightIcon={
                                      <i className="fa-solid fa-user"></i>
                                    }
                                    required
                                  />
                                </div>
                                <div className="col-md-6">
                                  <Field
                                    component={TextInput}
                                    label={t("createUserForm.lastName.label")}
                                    id="lastName"
                                    name="lastName"
                                    placeholder={t(
                                      "createUserForm.lastName.placeholder"
                                    )}
                                    disabled={!inputEnabled}
                                    rightIcon={
                                      <i className="fa-solid fa-user"></i>
                                    }
                                    required
                                  />
                                </div>
                                {values.userRoleType !== "RU" && (
                                  <div className="col-md-6">
                                    <Field
                                      rightIcon={
                                        <i className="fa-solid fa-envelope"></i>
                                      }
                                      disabled={!inputEnabled}
                                      component={TextInput}
                                      label={t("createUserForm.emailId.label")}
                                      placeholder={t(
                                        "createUserForm.emailId.placeholder"
                                      )}
                                      id="emailId"
                                      name="emailId"
                                      autoComplete="off"
                                      required
                                    />
                                  </div>
                                )}
                                {values.userRoleType !== "RU" && (
                                  <div className="col-md-6">
                                    <CusPhoneInput
                                      required
                                      id="mobileNumber"
                                      name="mobileNumber"
                                      label={t("createUserForm.mobileNo.label")}
                                      error={errors.mobileNumber}
                                      touched={touched.mobileNumber}
                                      value={values.mobileNumber}
                                      disabled={!inputEnabled || isSubmitting}
                                      onChange={(val) => {
                                        props.setFieldValue(
                                          "mobileNumber",
                                          val
                                        );
                                      }}
                                      onBlur={() => {
                                        props.validateField("mobileNumber");
                                        props.setFieldTouched("mobileNumber");
                                      }}
                                    />
                                  </div>
                                )}
                                {values.userRoleType !== "RU" &&
                                  currentUserId != companyUserId &&
                                  screenType !==
                                    USER_FORM_OPTION.UPDATE_ADMIN && (
                                    <div className="col-md-6">
                                      <Field
                                        disabled={!inputEnabled}
                                        component={PasswordInput}
                                        label={t(
                                          "createUserForm.password.label"
                                        )}
                                        placeholder={t(
                                          "createUserForm.password.placeholder"
                                        )}
                                        id="password"
                                        name="password"
                                        autoComplete="new-password"
                                        initValidation
                                      />
                                    </div>
                                  )}
                                {values.userRoleType !== "RU" &&
                                  currentUserId != companyUserId &&
                                  screenType !==
                                    USER_FORM_OPTION.UPDATE_ADMIN && (
                                    <div className="col-md-6">
                                      <Field
                                        disabled={!inputEnabled}
                                        component={PasswordInput}
                                        label={t(
                                          "createUserForm.confirmPass.label"
                                        )}
                                        placeholder={t(
                                          "createUserForm.confirmPass.placeholder"
                                        )}
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        initValidation
                                        // required={values.isCompanyPasswordChanged}
                                      />
                                    </div>
                                  )}

                                {currentUserId != companyUserId &&
                                  screenType !==
                                    USER_FORM_OPTION.UPDATE_ADMIN && (
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <div className="switch">
                                          <label className="mb-2 form-label">
                                            {t("createUserForm.isActiveLabel")}
                                          </label>
                                          <Field
                                            type="checkbox"
                                            label={t(
                                              "createUserForm.statusLabel"
                                            )}
                                            id="isActive"
                                            name="isActive"
                                            disabled={!inputEnabled}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                {(values.userRoleType === "CU" ||
                                  values.userRoleType === "RU") && (
                                  <div>
                                    <h6
                                      className="text-primary"
                                      id="schema-access"
                                    >
                                      {t("createUserForm.schemaAccess")}
                                    </h6>
                                    <hr />

                                    <div
                                      className="row"
                                      role="group"
                                      aria-labelledby="schema-access"
                                    >
                                      {configList.length <= 0 ? (
                                        <div
                                          className="align-items-center d-flex justify-content-center text-center text-no-data"
                                          style={{
                                            height: "70px",
                                          }}
                                          dangerouslySetInnerHTML={{
                                            __html: t(
                                              "errorKeys.noDataSchemeAssigned"
                                            ),
                                          }}
                                        ></div>
                                      ) : (
                                        configList.map((e) => {
                                          return (
                                            <div className="col-12 col-lg-6 col-md-4">
                                              <div className="form-check mb-3">
                                                <label>
                                                  <Field
                                                    disabled={disableSchema}
                                                    type="checkbox"
                                                    className="form-check-input me-2"
                                                    value={e.dataStreamConfigId}
                                                    name="dataStreamConfigId"
                                                    checked={values.dataStreamConfigId.includes(
                                                      "" + e.dataStreamConfigId
                                                    )}
                                                  />
                                                  {e.customDataStreamName}
                                                </label>
                                              </div>
                                            </div>
                                          );
                                        })
                                      )}
                                    </div>
                                  </div>
                                )}
                              </div>
                              {screenType === USER_FORM_OPTION.VIEW_USER ? (
                                <div className="row justify-content-end m-0">
                                  <PrimaryButton
                                    title={t("CommonKeys.backButtonTitle")}
                                    icon="fa-solid fa-arrow-left"
                                    onClick={() => {
                                      navigate(
                                        getPathPrefix("company-user/user-list")
                                      );
                                    }}
                                  />
                                </div>
                              ) : (
                                <div className="row justify-content-end m-0">
                                  <PrimaryButton
                                    type="submit"
                                    title={t("CommonKeys.saveButtonTitle")}
                                    icon="fa-regular fa-floppy-disk"
                                    disabled={
                                      !isValid || isSubmitting || !dirty
                                    }
                                  />

                                  <PrimaryButton
                                    title={t("CommonKeys.cancelButtonTitle")}
                                    className="btn btn-green w-auto ms-2"
                                    disabled={isSubmitting}
                                    onClick={() => {
                                      if (
                                        screenType ===
                                          USER_FORM_OPTION.VIEW_ROUTING_USER ||
                                        screenType ===
                                          USER_FORM_OPTION.UPDATE_ROUTING_USER
                                      ) {
                                        navigate(
                                          getPathPrefix(
                                            "company-user/routing-user-list"
                                          )
                                        );
                                      } else if (
                                        screenType ===
                                        USER_FORM_OPTION.UPDATE_ADMIN
                                      ) {
                                        navigate(getPathPrefix("dashboard"));
                                      } else {
                                        navigate(
                                          getPathPrefix(
                                            "company-user/user-list"
                                          )
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </Form>
                        )}
                      </Formik>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
