import React, { useState } from "react";
import Select from "react-select";
import { t } from "i18next";
import PrimaryButton from "../Form/Buttons/PrimaryButton";
import { useMemo } from "react";
import RuleBuilderConvert from "../../utils/converter/RuleBuilderConvert";
import { useQueryContext } from "../../features/selectedDataStream/context/RuleEndingProvider";

const DynamicMessage = ({
  id,
  isDynamicMessageConstruction,
  configList,
  groupData,
  value: dynamicMessage,
  staticMessage,
  placeholder,
  onDynamicMessageChange,
  onStaticMessageChange,
  messageTypeSelection,
  onMessageTypeChange,
  viewOnly,
}) => {
  const [textAddingPlace, setTextAddingPlace] = useState("");
  const [groupSelection, setGroupSelection] = useState("");
  const [fieldSelection, setFieldSelection] = useState("");

  const { query } = useQueryContext();

  const fieldsWithGroup = useMemo(() => {
    const allowedFieldIds = query.map((ele) => ele.column);
    const allowedFields = groupData.filter((ele) =>
      allowedFieldIds.find((e) => e == ele.customFieldsId)
    );

    return RuleBuilderConvert.aiccRBFieldConfigToGroupedFields(allowedFields, [
      ...configList.flatMap((e) => e.aiccDataStreamJsons),
      ...configList
        .flatMap((e) => e.apiDetails ?? [])
        .map((e) => ({
          ...e,
          jsonId: e.dataStreamConfigApiId,
          jsonFileSchemaName: e.apiName,
        })),
    ]);
  }, [configList, groupData, query]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const finalColumnList = useMemo(
    () =>
      fieldsWithGroup.find((e) => "" + e.value === "" + groupSelection)
        ?.subFields ?? [][groupSelection]
  );

  const addTotext = () => {
    const foundedData = groupData.find(
      (e) => "" + e.customFieldsId === "" + fieldSelection
    );

    onDynamicMessageChange(
      `${dynamicMessage?.slice(0, textAddingPlace) ?? ""} {${foundedData.queryString}} ${dynamicMessage?.slice(textAddingPlace) ?? ""
      }`
    );
  };

  const messageTypeHandler = (e) => {
    if (onMessageTypeChange) {
      onMessageTypeChange(e.target.value);
    }
  };
  return (
    <>
      <div>
        {isDynamicMessageConstruction && (
          <div className="mb-3">
            <label className="form-label">{t(
              "createRuleEngine.settingsTab.messageOptions.messageType"
            )}</label>
            <div>
              <div className="form-check form-check-inline">
                <input
                  id={"staticMessageSelection" + (id ?? "")}
                  type="radio"
                  className="form-check-input"
                  value="static"
                  checked={messageTypeSelection === "static"}
                  onChange={messageTypeHandler}
                />
                <label
                  className="form-check-label"
                  htmlFor={"staticMessageSelection" + (id ?? "")}
                >
                  {t(
                    "createRuleEngine.settingsTab.messageOptions.static"
                  )}
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  id={"dynamicMessageSelection" + (id ?? "")}
                  type="radio"
                  className="form-check-input"
                  value="dynamic"
                  checked={messageTypeSelection === "dynamic"}
                  onChange={messageTypeHandler}
                />
                <label
                  className="form-check-label"
                  htmlFor={"dynamicMessageSelection" + (id ?? "")}
                >
                  {t(
                    "createRuleEngine.settingsTab.messageOptions.dynamic"
                  )}
                </label>
              </div>
            </div>
          </div>
        )}

        {messageTypeSelection === "dynamic" ? (
          <div>
            <span className="text-danger mb-3 d-block" style={{ fontSize: "14px", fontWeight: "bold" }}>{t(
              "createRuleEngine.settingsTab.messageOptions.dynamicMessageNote"
            )}</span>
            <div className="d-flex gap-3 mb-3">
              <div className="w-100">
                <Select
                  options={fieldsWithGroup}
                  placeholder="Group select"
                  onChange={(options) => {
                    setGroupSelection(options.value);
                  }}
                  classNamePrefix={"select"}
                  menuPosition={"fixed"}
                />
              </div>
              <div className="w-100">
                <Select
                  options={finalColumnList}
                  placeholder="Field select"
                  onChange={(options) => {
                    setFieldSelection(options.value);
                  }}
                  classNamePrefix={"select"}
                  menuPosition={"fixed"}
                />
              </div>
              <PrimaryButton title={"Add"} onClick={addTotext} />
            </div>
            <textarea
              style={{
                minHeight: "120px",
              }}
              name=""
              className="form-control"
              rows="5"
              placeholder={placeholder}
              value={dynamicMessage}
              onKeyUp={(e) => {
                setTextAddingPlace(e.target.selectionEnd);
              }}
              onMouseUp={(e) => {
                setTextAddingPlace(e.target.selectionEnd);
              }}
              onChange={(e) => {
                onDynamicMessageChange(e.target.value);
              }}
            />
          </div>
        ) : (
          <div>
            <textarea
              style={{
                minHeight: "120px",
              }}
              name=""
              className="form-control"
              rows="5"
              disabled={viewOnly}
              placeholder={placeholder}
              value={staticMessage}
              onChange={(e) => {
                onStaticMessageChange(e.target.value);
              }}
            ></textarea>
          </div>
        )}
      </div>
    </>
  );
};

export default DynamicMessage;
