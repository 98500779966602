const TextArea = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, isSubmitting }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  required,
  noPad,
  initValidation,
  ...props
}) => (
  <div className={["form-group", noPad ? "mb-0" : ""].join(" ")}>
    <label className="mb-2 form-label">
      {label}
      {required && (
        <sup>
          <i
            className="fa-solid fa-star-of-life"
            style={{
              color: "red",
              fontSize: "5px",
              marginBottom: "5px",
            }}
          ></i>
        </sup>
      )}
    </label>
    <textarea
      type="text"
      className="form-control"
      {...field}
      {...{ ...props, required }}
      disabled={isSubmitting}
    />
    {(initValidation
      ? errors[field.name]
      : touched[field.name] && errors[field.name]) && (
      <h6 className="text-danger mt-1 input-error-text">{errors[field.name]}</h6>
    )}
  </div>
);

export default TextArea;
