export const BASE_URL =
  process.env.REACT_APP_BASE_URL ?? "https://aic-api.codesrv.com:7002/";
export const ADMIN_BASE_URL =
  process.env.REACT_APP_ADMIN_BASE_URL ?? "https://aic-api.codesrv.com:7001/";

export const OTP_TIME = 45;

export const KEYSET_GROUP_NAME = "KeySet Group"

export const APPSTRING = {
  left_panel_title: "Welcome To Our AI Control",
  left_panel_content:
    "Grursus mal suada faci lisis Lorem ipsum dolarorit more ametion consectetur elit. Vesti at bulum nec odio aea the dumm ipsumm ipsum that dolocons rsus mal suada and fadolorit to the dummy.",
  forgot_password: "Forgot Password?",
  reset_password: "Reset Password",
  password_change: "Password Changed!",
  password_success: "Your password has been changed successfully.",
  check_email: "Check Your Email",
  otp_content:
    "Enter 6 Digits OTP sent to the email specified during registration",
};

export const buttonStrings = {
  logOut: "Logout",
  reset_link: "SEND RESET LINK",
  backTo: "Back to",
  login_hyphen: "Log-in",
  update_password: "Update Password",
  continue: "Continue",
  submit: "SUBMIT",
};

export const placeholders = {
  password: "Enter your password",
  userName: "Enter your user name",
  userName_Email: "Enter your user name or email",
  reset_password: "Password",
  confirm_password: "Confirm Password",
  current_password: "Current Password",
  old_password: "Old Password",
};

export const lables = {
  password: "Password",
  userNameEmail: "Email",
  rememberMe: "Remember Me",
};

export const icons = {
  user: "fa-solid fa-user field-icon",
  lock: "fa-solid fa-lock field-icon",
  solid: "fa-sharp fa-solid fa-check",
  language: "fa fa-language",
};

export const IMAGEPATH = {
  ai_cost_control_logo: "/assets/images/AI_Cost_Control_logo.png",
  ai_brain: "/assets/images/ai-brain.jpg",
};
