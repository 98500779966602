import React, { useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import openApi from "../services/apis/openApi";
import { useEffect } from "react";
import CenterLoading from "./common/CenterLoading";
import AiccBreadCrumb from "./common/AiccBreadCrumb";
import { getPathPrefix } from "../utils/coreUtils";
import companyUserApi from "../services/apis/companyUserApi";
import { useDispatch } from "react-redux";
import { menuReducerTypes } from "../library/redux/reducers/menuReducer";
import menuActions from "../library/redux/actions/menuActions";
import StorageManager from "../features/library/utils/storageManager";

function Index() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [t, i18n] = useTranslation();

  const [configList, setConfigList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [companyDetails, setCompanyDetails] = useState();

  const getDataStreamConfig = async () => {
    setIsLoading(true);
    try {
      const { data } = await openApi.getDataStreamConfig();

      await getCompanyUserDetailsById();
      if (typeof data !== "string") {
        setConfigList(data ?? []);
      }
    } catch (error) {
      console.log("error", error);
    }
    setIsLoading(false);
  };

  const getCompanyUserDetailsById = async () => {
    // setIsLoading(true);
    try {
      const companyUserDetails = await companyUserApi.getCompanyUserDetailsById(
        {
          companyUserId: localStorage.getItem("companyUserId"),
        }
      );

      setCompanyDetails(companyUserDetails.data);
    } catch (error) {
      console.log("checking", error);
    }
    // setIsLoading(false);
  };

  useEffect(() => {
    getDataStreamConfig();
  }, []);

  const finalDataStreams = useMemo(() => {
    if (companyDetails?.userRoleType !== "CA") {
      return configList.filter((e) =>
        companyDetails?.dataStreamConfigIds.includes(e.dataStreamConfigId)
      );
    }

    return configList;
  }, [
    companyDetails?.dataStreamConfigIds,
    companyDetails?.userRoleType,
    configList,
  ]);

  return (
    <div className="container-scroller">
      <div className="container-fluid page-body-wrapper p-0">
        <div className="sidebar-wrapper"></div>
        <div className="main-panel">
          <div className="content-wrapper">
            <AiccBreadCrumb
              homeLink={getPathPrefix("dashboard")}
              title={t("dashboard.breadCrumb.title")}
              items={t("dashboard.breadCrumb.items", {
                returnObjects: true,
              })}
            />
            <div className="row">
              <div className="col-md-12 grid-margin">
                <div className="row">
                  <div className="col-12 col-xl-12 mb-4 mb-lg-5">
                    <h4 className=" text-center">{t("dashboard.title")}</h4>
                  </div>
                  <div className="col-12">
                    <div className="dashboard-card-area col-lg-9 mx-auto">
                      <div className="row g-3">
                        {isLoading ? (
                          <CenterLoading />
                        ) : finalDataStreams.length > 0 ? (
                          finalDataStreams.map((e) => (
                            <div
                              className="col-lg-6 col-md-6 col-12"
                              key={e.dataStreamConfigId}
                            >
                              <div
                                className="h-100"
                                onClick={() => {
                                  if (companyDetails?.userRoleType === "CA") {
                                    navigate(
                                      getPathPrefix(
                                        `selected-datastream/config-json/${e.dataStreamConfigId}`
                                      )
                                    );
                                  } else {
                                    navigate(
                                      getPathPrefix(
                                        "selected-datastream/rule-engine/versions"
                                      )
                                    );
                                  }
                                  // dispatch(menuActions(e));
                                  StorageManager.setSelectedDataStream(e);
                                }}
                              >
                                <div
                                  className="card db-card radius-15 h-100"
                                  style={{
                                    marginBottom: "unset",
                                  }}
                                >
                                  <div className="card-body">
                                    <div className="d-flex align-items-center">
                                      <div
                                        style={{
                                          wordWrap: "break-word",
                                          width: "100%",
                                        }}
                                      >
                                        <h4>{e.customDataStreamName}</h4>
                                        {/* <p>{e.aiccDataStreamJsons.length}</p> */}
                                      </div>
                                      {/* <div className="widget-icon-large ms-auto">
                                    <img
                                      src="/assets/images/estimate.png"
                                      alt
                                      className="widget-icon"
                                    />
                                    <svg
                                      className="peity"
                                      height={92}
                                      width={92}
                                    >
                                      <path
                                        d="M 46 0 A 46 46 0 1 1 13.473088065418807 13.473088065418814 L 17.008621971351545 17.008621971351555 A 41 41 0 1 0 46 5"
                                        data-value={7}
                                        fill="rgb(255, 255, 255)"
                                      />
                                      <path
                                        d="M 13.473088065418807 13.473088065418814 A 46 46 0 0 1 45.99999999999999 0 L 45.99999999999999 5 A 41 41 0 0 0 17.008621971351545 17.008621971351555"
                                        data-value={1}
                                        fill="rgba(255, 255, 255)"
                                      />
                                    </svg>
                                    <span className="circle bg-white" />
                                  </div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div
                            className="align-items-center d-flex justify-content-center text-center text-no-data"
                            style={{
                              height: "60vh",
                            }}
                            dangerouslySetInnerHTML={{
                              __html: t("dashboard.notConfigured"),
                            }}
                          ></div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer className="footer">
            <div className="d-sm-flex justify-content-center">
              <p className="mb-0">
                AI Cost Control @2022 | Developed By :{" "}
                <a href="#" target="_blank">
                  Om Software
                </a>
              </p>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
}

export default Index;
